import React, {useState} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import API_URL from '../config';
import LoginUpperSection from './LoginUpperSection';
import LoginLowerSection from './LoginLowerSection';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
const RequestPasswordFrom = () => {
    const [email, setEmail] = useState('');   
    const navigate = useNavigate();
    const { t, i18n } = useTranslation(); // Access i18n instance
    const lang = i18n.language; // Get the current language
    const handleSubmit = async (e) => {
        e.preventDefault();    

        try {
            const response = await fetch(API_URL+'api/auth/Password/RequestReset', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ email }),
            });
            const responseBody = await response.text();          

            
            if (response.ok) {
                message.success(t("changePassword.checkEmail"));
                navigate(-1);
            } else if (responseBody.includes('ApplicationUser : NullData')){
                // Błąd logowania
                message.error(t("changePassword.userNotFound"));
            } else{
                message.error(t("message.error"));
            }
        } catch (error) {
            console.error('Błąd logowania:', error);
            message.error(t("message.error"));
        }     
    };

    return (
        <form className="form-container" onSubmit={handleSubmit}>
            <h3>{t("changePassword.title")}</h3>  
            <div>
                <label>Email:</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </div>           
            <button type="submit">{t("common.send")}</button>
            
        </form>
    );
};


const RequestPasswordPage = () => {
    return (
        <Container>
           <LoginUpperSection/>
            <Row style={{backgroundColor: 'rgb(51, 51, 51)'}}>
                <Col md={3} className="mx-auto">
                    <RequestPasswordFrom/>
                </Col>               
            </Row>
            <LoginLowerSection/>
        </Container>
    );
};

export default RequestPasswordPage;
