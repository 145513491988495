import React, {useState} from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { useTranslation } from 'react-i18next';

const LoginUpperSection = () => {
    const { t, i18n } = useTranslation(); // Access i18n instance
    const lang = i18n.language; // Get the current language

    return (
        
            <Row style={{backgroundColor: 'rgb(51, 51, 51)'}}>
                <section className="flex-shrink-0" style={{backgroundColor: 'rgb(51, 51, 51)'}}>
                    <div className="container mx-auto py-12 lg:py-14 xl:py-20">
                        <div className="flex flex-col sm:flex-row flex-wrap gap-10 justify-center">
                            <span className="hidden justify-center justify-end justify-start"></span>
                            <div className="block-list-item w-full">
                                <div className="flex-shrink-0 relative mb-6 aspect-w-16 aspect-h-9">
              <span style={{
                  boxSizing: 'border-box',
                  display: 'block',
                  overflow: 'hidden',
                  width: 'initial',
                  height: 'initial',
                  background: 'none',
                  opacity: 1,
                  border: '0px none',
                  margin: '0px',
                  padding: '0px',
                  position: 'absolute',
                  inset: '0px'
              }}>
                <img alt="Custom Itineraries" src="img/photo-4.jpg" className="rounded-lg md:rounded-xl lg:rounded-2xl" style={{
                    position: 'absolute',
                    inset: '0px',
                    boxSizing: 'border-box',
                    padding: '0px',
                    border: 'medium none',
                    margin: 'auto',
                    display: 'block',
                    width: '0px',
                    height: '0px',
                    minWidth: '100%',
                    maxWidth: '100%',
                    minHeight: '100%',
                    maxHeight: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center center'
                }}/><noscript></noscript>
              </span>
                                </div>
                                <div className="text-center">
                                    <p className="heading-medium mb-4" style={{color: 'rgb(255, 255, 255)', fontFamily: "'Rufina', serif", fontWeight: 700}}>{t("login.loginPageSectionOneTitle")}</p>
                                    <div className="rich-text-block" style={{color: 'rgb(255, 255, 255)'}}>{t("login.loginPageSectionOne")}
                                    </div>
                                </div>
                            </div>
                            <div className="block-list-item w-full">
                                <div className="flex-shrink-0 relative mb-6 aspect-w-16 aspect-h-9">
              <span style={{
                  boxSizing: 'border-box',
                  display: 'block',
                  overflow: 'hidden',
                  width: 'initial',
                  height: 'initial',
                  background: 'none',
                  opacity: 1,
                  border: '0px none',
                  margin: '0px',
                  padding: '0px',
                  position: 'absolute',
                  inset: '0px'
              }}>
                <img alt="Multilingual Guides" src="img/photo-5.webp" className="rounded-lg md:rounded-xl lg:rounded-2xl" style={{
                    position: 'absolute',
                    inset: '0px',
                    boxSizing: 'border-box',
                    padding: '0px',
                    border: 'medium none',
                    margin: 'auto',
                    display: 'block',
                    width: '0px',
                    height: '0px',
                    minWidth: '100%',
                    maxWidth: '100%',
                    minHeight: '100%',
                    maxHeight: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center center'
                }}/><noscript></noscript>
              </span>
                                </div>
                                <div className="text-center">
                                    <p className="heading-medium mb-4" style={{color: 'rgb(255, 255, 255)', fontFamily: "'Rufina', serif", fontWeight: 700}}>
                                    {t("login.loginPageSectionTwoTitle")}</p>
                                    <div className="rich-text-block" style={{color: 'rgb(255, 255, 255)'}}>{t("login.loginPageSectionTwo")}
                                    </div>
                                </div>
                            </div>
                            <div className="block-list-item w-full">
                                <div className="flex-shrink-0 relative mb-6 aspect-w-16 aspect-h-9">
              <span style={{
                  boxSizing: 'border-box',
                  display: 'block',
                  overflow: 'hidden',
                  width: 'initial',
                  height: 'initial',
                  background: 'none',
                  opacity: 1,
                  border: '0px none',
                  margin: '0px',
                  padding: '0px',
                  position: 'absolute',
                  inset: '0px'
              }}>
                <img alt="Historical Tours" src="img/photo-6.jpg" decoding="async" data-nimg="fill" className="rounded-lg md:rounded-xl lg:rounded-2xl" style={{
                    position: 'absolute',
                    inset: '0px',
                    boxSizing: 'border-box',
                    padding: '0px',
                    border: 'medium none',
                    margin: 'auto',
                    display: 'block',
                    width: '0px',
                    height: '0px',
                    minWidth: '100%',
                    maxWidth: '100%',
                    minHeight: '100%',
                    maxHeight: '100%',
                    objectFit: 'cover',
                    objectPosition: 'center center'
                }}/><noscript></noscript>
              </span>
                                </div>
                                <div className="text-center">
                                    <p className="heading-medium mb-4" style={{color: 'rgb(255, 255, 255)', fontFamily: "'Rufina', serif", fontWeight: 700}}>
                                    {t("login.loginPageSectionThreeTitle")}</p>
                                    <div className="rich-text-block" style={{color: 'rgb(255, 255, 255)'}}>
                                    {t("login.loginPageSectionThree")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>                           
            </Row>        
    );
};

export default LoginUpperSection;
