import React, { useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import '../../blog.css';
const AtrakcjeKrakow = () => {
  const { t, i18n } = useTranslation(); // Access i18n instance
  const lang = i18n.language; // Get the current language


  return (

    <Container style={{ color: "rgb(0, 0, 0)", paddingTop: "10px", fontFamily: "PT Serif" }}>
      <Helmet>
      <html lang={lang} />
      <meta charSet="utf-8" />
        <meta name="description" content={"Kraków - " + t("homepage.descriptionBlogtTag")} />
        <meta name="keywords" content={t("homepage.keyWords")} />
        <title>Najlepsze atrakcje turystyczne w Krakowie</title>
        <link rel="canonical" href={`https://tourpilot.eu/${lang}/blog/krakow-atrakcje`} />
        <link rel="alternate" href="https://tourpilot.eu/pl/blog/krakow-atrakcje" hreflang="pl" />
        <link rel="alternate" href="https://tourpilot.eu/en/blog/krakow-atrakcje" hreflang="en" />
        <link rel="alternate" href="https://tourpilot.eu/pl/blog/krakow-atrakcje" hreflang="x-default" />
      </Helmet>
      <div className='blog blog-articale-container'>
        <div className='blog-article1'>

          <h1>Atrakcje Turystyczne w Krakowie - przewodnik</h1>
          <div style={{ width: "100%", height: "300px" }}>
            <img src="img/karkow.jpg" alt="Przewodnik Kraków atrakcje" />
          </div>

          <h2>Atrakcje w Krakowie</h2>

          <p>Kraków, dawna stolica Polski, to miasto pełne historii, kultury i niezapomnianych widoków. Znany ze swojej pięknej architektury, tętniącego życiem rynku i licznych zabytków, Kraków jest jednym z najważniejszych miejsc turystycznych w Polsce. W tym artykule przedstawiamy najważniejsze atrakcje turystyczne Krakowa, które warto odwiedzić.</p>

          <h2>Rynek Główny i Sukiennice</h2>
          <p>Rynek Główny w Krakowie to jeden z największych średniowiecznych placów w Europie i serce miasta. Znajdują się tu piękne kamienice, Bazylika Mariacka oraz słynne Sukiennice, które od wieków pełnią funkcję handlową. Warto wejść na wieżę Bazyliki Mariackiej, aby podziwiać widok na cały rynek i posłuchać hejnału granego co godzinę z jej wieży.</p>

          <h2>Zamek Królewski na Wawelu</h2>
          <p>Wawel to symbol polskiej państwowości i jedno z najważniejszych miejsc historycznych w kraju. Na wzgórzu Wawelskim znajduje się Zamek Królewski oraz Katedra Wawelska, miejsce koronacji polskich królów. Zwiedzając Wawel, można podziwiać królewskie komnaty, skarbiec oraz piękne ogrody. Warto również zobaczyć słynną Smoczą Jamę – legendarną jaskinię, w której według legendy mieszkał smok wawelski.</p>

          <h2>Kazimierz – żydowska dzielnica Krakowa</h2>
          <p>Kazimierz to historyczna dzielnica żydowska, która obecnie jest jednym z najbardziej klimatycznych miejsc w Krakowie. Wąskie uliczki, zabytkowe synagogi i liczne kawiarnie tworzą niepowtarzalną atmosferę. Warto odwiedzić Synagogę Stara oraz Muzeum Galicja, które przybliża historię Żydów w Polsce. Kazimierz jest także miejscem wielu festiwali, takich jak Festiwal Kultury Żydowskiej, który przyciąga turystów z całego świata.</p>

          <h2>Kopalnia Soli w Wieliczce</h2>
          <p>Kopalnia Soli w Wieliczce, położona niedaleko Krakowa, to jedno z najbardziej niezwykłych miejsc w Polsce. To podziemne miasto z kaplicami, jeziorami i rzeźbami wykonanymi w soli. Trasa turystyczna prowadzi przez kilometry korytarzy i komnat, a jedną z najpiękniejszych jest Kaplica św. Kingi. Kopalnia Soli w Wieliczce znajduje się na liście światowego dziedzictwa UNESCO i jest miejscem, które koniecznie trzeba zobaczyć.</p>

          <h2>Muzeum Narodowe i Sukiennice</h2>
          <p>Kraków to miasto pełne muzeów, a jednym z najważniejszych jest Muzeum Narodowe. Jego główny oddział znajduje się w Sukiennicach na Rynku Głównym. Warto zobaczyć Galerię Sztuki Polskiej XIX wieku, gdzie można podziwiać dzieła najwybitniejszych polskich malarzy, takich jak Jan Matejko czy Józef Chełmoński. Muzeum Narodowe oferuje również liczne wystawy czasowe, które przyciągają miłośników sztuki z całego świata.</p>

          <h2>Zakończenie</h2>
          <p>Kraków to miasto, które zachwyca na każdym kroku. Od historycznych zabytków, przez nowoczesne muzea, po tętniące życiem dzielnice – każdy znajdzie tu coś dla siebie. Zapraszamy do odkrywania Krakowa z <a href={`https://tourpilot.eu/${lang}`}>tourpilot.eu</a> i korzystania z naszych usług, które sprawią, że każda podróż będzie niezapomnianym przeżyciem.</p>
        </div>
      </div>
    </Container >

  );
};
export default AtrakcjeKrakow;
