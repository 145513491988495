import React, { useState, useEffect } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import axios from 'axios';
import ApiClient from './ApiClient';
import { useTranslation } from 'react-i18next';
import API_URL from '../config';
import { Select, message, Modal , Result, Checkbox,Button } from 'antd';
import { Helmet } from "react-helmet";

const RegistrationGuideForm = () => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [buildingNumber, setBuildingNumber] = useState('');
    const [street, setStreet] = useState('');
    const [city, setCity] = useState('');
    const [postCode, setPostCode] = useState('');
    const [phoneNumber, setPhoneNumber] = useState();
    const [licenseNumber, setLicenseNumber] = useState('');
    const [countryId, setCountry] = useState(0);
    const [countryDataSource, setCountryDataSource] = useState([]);
    const [errorMessage, setErrorMessage] = useState('');
    const { t, i18n } = useTranslation(); // Access i18n instance
    const lang = i18n.language; // Get the current language
    const [companyName, setCompanyName] = useState('');
    const [taxNumber, setTaxNumber] = useState('');
    const [termsConsent, setTermsConsent] = useState(false);
    const [policyConsent, setPolicyConsent] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
      };
    
      const handleOk = () => {
        setIsModalOpen(false);
        window.location.href = `/${lang}`;
      };    
    
    useEffect(() => {
        fetchCountriesData(); // Call the fetchData function when the component mounts
    }, []);

    const fetchCountriesData = async () => {

        let result = await axios.get(API_URL + 'api/dictionary/countries');
        setCountryDataSource(result.data);
    }
    
    const handleChange = (value) => {
        setCountry(value);
    }
    const handleCheckboxTerms = (e) => {
        setTermsConsent(e.target.checked);
    }
    const handleCheckboxPolicy = (e) => {
        setPolicyConsent(e.target.checked);
    }
    const handleSubmit = async (e) => {
        e.preventDefault();

        if (errorMessage.length > 0)
            return;
        // Walidacja haseł
        if (password.length < 8 || !/(?=.*\d)(?=.*\W)/.test(password)) {
            message.error(t("login.passworRequirments"));
            return;
        }

        if (password !== confirmPassword) {
            message.error(t("login.passwordDoesntMatch"));
            return;
        }
        if (countryId === 0) {
            message.error(t("message.Pleaseselectcountry"));
            return;
        }
        if (!termsConsent) {
            message.error(t("login.termsConsentRequired"));
            return;
        }
        if (!policyConsent) {
            message.error(t("login.termsPrivacyRequired"));
            return;
        }
        // Utwórz obiekt z danymi
        const data = {
            firstName,
            lastName,
            email,
            password,
            termsconsent: termsConsent, // Przykładowa wartość zgody na warunki
            marketingconsent: false, // Przykładowa wartość zgody na marketing
            street,
            city,
            postCode,
            buildingNumber,
            licenseNumber,
            countryId,
            phoneNumber,
            companyName,
            taxNumber
        };

        try {
            setIsSubmitting(true);

            // Wyślij dane do API
            const response = await ApiClient('POST', 'api/auth/register/guide', data);

            if (response.ok) {
                // Rejestracja zakończona sukcesem
                // Możesz przekierować użytkownika do innej strony lub wykonać inne działania
                showModal(true);

            } else if (response.status === 409) {
                // Rejestracja zakończona sukcesem
                // Możesz przekierować użytkownika do innej strony lub wykonać inne działania
                message.info(t("login.emailTaken"));
            } else {
                // Obsłuż błąd rejestracji
                message.error(t("message.error"));
            }
        } catch (error) {
            message.error(t("message.error"));
        } finally {
            // Enable the button after the form submission is complete
            setIsSubmitting(false);
        }
    };
    const validatePhoneNumber = () => {
        const phoneNumberRegex = /^(\+\d+|\+\d+(?:\s\d+)+)$/;

        if (phoneNumberRegex.test(phoneNumber)) {
            setErrorMessage('');

        } else {
            setErrorMessage(t("message.wrongPhoneNumber"));
        }
    };

    return (
        <form className="form-container" onSubmit={handleSubmit}>
            <h3>{t("login.registerGuideTitle")}</h3>
            <div className="flex flex-col sm:flex-row flex-wrap gap-10 justify-center"
                style={{ color: 'rgb(255, 255, 255)' }}>

            </div>

            <div>
                <label>{t("login.firstName")}:*</label>
                <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>{t("login.lastName")}:*</label>
                <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>{t("login.companyName")}</label>
                <input
                    type="text"
                    value={companyName}
                    onChange={(e) => setCompanyName(e.target.value)}
                />
            </div>
            <div>
                <label>{t("login.taxnumber")}*:</label>
                <input
                    type="text"
                    value={taxNumber}
                    onChange={(e) => setTaxNumber(e.target.value)}
                    required

                />
            </div>
            <div>
                <label>Email:*</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>{t("login.password")}:*</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>{t("login.confrimPassword")}:*</label>
                <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>{t("login.phoneWithPrefix")}:*</label>

                <input
                    type="phonenumber"
                    value={phoneNumber}
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    onBlur={validatePhoneNumber}
                    required
                />
                <p style={{ color: 'red' }}>{errorMessage}</p>
            </div>
            <div>
                <label>{t("login.licenceNumber")}:</label>
                <input
                    type="text"
                    value={licenseNumber}
                    onChange={(e) => setLicenseNumber(e.target.value)}

                />
            </div>
            <div>
                <label>{t("login.street")}:*</label>
                <input
                    type="text"
                    value={street}
                    onChange={(e) => setStreet(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>{t("login.buildingNumber")}:*</label>
                <input
                    type="text"
                    value={buildingNumber}
                    onChange={(e) => setBuildingNumber(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>{t("login.city")}:*</label>
                <input
                    type="text"
                    value={city}
                    onChange={(e) => setCity(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>{t("login.postCode")}:*</label>
                <input
                    type="text"
                    value={postCode}
                    onChange={(e) => setPostCode(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>{t("login.country")}:*</label>
                <Select
                    style={{ width: 120 }}
                    onChange={handleChange}
                    options={countryDataSource}
                    required

                />
            </div>
            <div>           
                <Checkbox                    
                    onChange={handleCheckboxTerms}                   
                    required
                >  {t("login.termsConsent")} <a href='02. Regulamin Organizatora.pdf' target="_blank">{t("regulations.regulations")}</a>*</Checkbox>;
                 <Checkbox                    
                    onChange={handleCheckboxPolicy}                   
                    required
                >{t("login.policyConsent")} <a href='privacy policy.pdf' target="_blank">{t("login.policy")}</a>*</Checkbox>;

            </div>
            <button type="submit" disabled={isSubmitting}>{isSubmitting ?  t("login.inProgres")  : t("login.register")} </button>
            <Modal open={isModalOpen}  footer={[
          <Button key="ok" onClick={handleOk}>
            OK
          </Button>          
        ]}>
            <Result
    status="success"
    title={t("login.emailConfirmationCheck") + " "  + t("login.emailConfirmationCheck1")} 

   
  />
            </Modal>
        </form>
        
    );
};

const RegisterGuidePage = () => {
    const { t, i18n } = useTranslation(); // Access i18n instance
    const lang = i18n.language; // Get the current language
    
    return (
        <Container>
            <Helmet>
                    <meta charSet="utf-8" />  
                    <html lang={lang} />
                    <meta name="description" content={t("homepage.descriptionTag")} />
                    <meta name="keywords" content={t("homepage.keyWords")} />
                    <title>{t('login.registerGuideTitle')}</title>                    
                    <link rel="canonical" href={`https://tourpilot.eu/${lang}/register/guide`} />
                    <link rel="alternate" href="https://tourpilot.eu/pl/register/guide" hreflang="pl" />
                    <link rel="alternate" href="https://tourpilot.eu/en/register/guide" hreflang="en" />
                    <link rel="alternate" href="https://tourpilot.eu/pl/register/guide" hreflang="x-default" />
                </Helmet>
            <Row style={{ backgroundColor: 'rgb(51, 51, 51)' }}>
                <section className="flex-shrink-0" style={{ backgroundColor: 'rgb(51, 51, 51)' }}>
                    <div className="container mx-auto py-12 lg:py-14 xl:py-20">
                        <div className="flex flex-col sm:flex-row flex-wrap gap-10 justify-center">
                            <span className="hidden justify-center justify-end justify-start"></span>
                            <div className="block-list-item w-full">
                                <div className="flex-shrink-0 relative mb-6 aspect-w-16 aspect-h-9">
                                    <span style={{
                                        boxSizing: 'border-box',
                                        display: 'block',
                                        overflow: 'hidden',
                                        width: 'initial',
                                        height: 'initial',
                                        background: 'none',
                                        opacity: 1,
                                        border: '0px none',
                                        margin: '0px',
                                        padding: '0px',
                                        position: 'absolute',
                                        inset: '0px'
                                    }}>
                                        <img alt="Custom Itineraries" src="img/photo-4.jpg" className="rounded-lg md:rounded-xl lg:rounded-2xl"
                                            style={{
                                                position: 'absolute',
                                                inset: '0px',
                                                boxSizing: 'border-box',
                                                padding: '0px',
                                                border: 'medium none',
                                                margin: 'auto',
                                                display: 'block',
                                                width: '0px',
                                                height: '0px',
                                                minWidth: '100%',
                                                maxWidth: '100%',
                                                minHeight: '100%',
                                                maxHeight: '100%',
                                                objectFit: 'cover',
                                                objectPosition: 'center center'
                                            }} /><noscript></noscript>
                                    </span>
                                </div>
                                <div className="text-center">
                                    <p className="heading-medium mb-4" style={{
                                        color: 'rgb(255, 255, 255)',
                                        fontFamily: "'Rufina', serif",
                                        fontWeight: 700
                                    }}>{t("login.registerGuideCol1Title")}</p>
                                    <div className="rich-text-block" style={{ color: 'rgb(255, 255, 255)' }}>
                                    {t("login.registerGuideCol1")}


                                    </div>
                                </div>
                            </div>
                            <div className="block-list-item w-full">

                                <RegistrationGuideForm />
                                <div className="text-center">


                                </div>
                            </div>
                            <div className="block-list-item w-full">
                                <div className="flex-shrink-0 relative mb-6 aspect-w-16 aspect-h-9">
                                    <span style={{
                                        boxSizing: 'border-box',
                                        display: 'block',
                                        overflow: 'hidden',
                                        width: 'initial',
                                        height: 'initial',
                                        background: 'none',
                                        opacity: 1,
                                        border: '0px none',
                                        margin: '0px',
                                        padding: '0px',
                                        position: 'absolute',
                                        inset: '0px'
                                    }}>
                                        <img alt="Historical Tours" src="img/photo-6.jpg" decoding="async" data-nimg="fill"
                                            className="rounded-lg md:rounded-xl lg:rounded-2xl" style={{
                                                position: 'absolute',
                                                inset: '0px',
                                                boxSizing: 'border-box',
                                                padding: '0px',
                                                border: 'medium none',
                                                margin: 'auto',
                                                display: 'block',
                                                width: '0px',
                                                height: '0px',
                                                minWidth: '100%',
                                                maxWidth: '100%',
                                                minHeight: '100%',
                                                maxHeight: '100%',
                                                objectFit: 'cover',
                                                objectPosition: 'center center'
                                            }} /><noscript></noscript>
                                    </span>
                                </div>

                                <div className="text-center">
                                    <p className="heading-medium mb-4" style={{
                                        color: 'rgb(255, 255, 255)',
                                        fontFamily: "'Rufina', serif",
                                        fontWeight: 700
                                    }}>{t("login.registerGuideCol2Title")}</p>
                                    <div className="rich-text-block" style={{ color: 'rgb(255, 255, 255)' }}>
                                    {t("login.registerGuideCol2")}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

            </Row>
            
        </Container>
    );
};

export default RegisterGuidePage;
