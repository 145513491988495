import React, { useState, useEffect } from 'react';
import { Tabs, Input } from 'antd';

const { TabPane } = Tabs;

const LanguageInput = ({ value = {}, onChange, rows = 1 }) => {
  const [content, setContent] = useState({
    PL: '',
    EN: '',
    ES: '',
    DE: '',
    RU: ''
  });

  useEffect(() => {
    try {
      const parsedValue = JSON.parse(value);
      setContent(parsedValue);
    } catch (error) {
      console.error('Invalid JSON string:', error);
    }
  }, [value]);

  const triggerChange = (changedValue) => {
    if (onChange) {
      onChange({
        ...content,
        ...changedValue,
      });
    }
  };

  const handleInputChange = (language, value) => {
    const updatedContent = { ...content, [language]: value };
    setContent(updatedContent);
    triggerChange({ [language]: value.trim()});
  };

  return (
    <Tabs defaultActiveKey="PL">
      {['PL', 'EN', 'ES', 'DE','RU'].map((language) => (
        <TabPane tab={language} key={language}>
          <Input.TextArea
            rows={rows}
            value={content[language]}
            onChange={(e) => handleInputChange(language, e.target.value)}
            placeholder={`Enter text in ${language}`}
          />
        </TabPane>
      ))}
    </Tabs>
  );
};

export default LanguageInput;
