import React, { useState, useEffect } from 'react';
import { UploadOutlined } from '@ant-design/icons';
import '../GuideAddTourForm.css';
import LocationAutosuggest from "./LocationAutosuggest.js";
import PriceComponent from "./PriceComponent.js";
import LanguageInput from "./LanguageInput.js";
import LanguageTextArea from "./LanguageTextArea.js";
import LanguageSelector from "./LanguageSelector.js";
import { Row, Col } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Upload, Button, Form, Input, message, Select, InputNumber, Radio } from 'antd';
import axios from 'axios';
import API_URL from '../config';
import { useNavigate } from 'react-router-dom';

const { TextArea } = Input;
const GuideAddTourForm = () => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [location, setLocation] = useState({});
  const [errors, setErrors] = useState({});
  const [fileList, setFileList] = useState([]);

  const [htmlContent, setHtmlContent] = useState('');
  const { t, i18n } = useTranslation(); // Access i18n instance
  const lang = i18n.language; // Get the current language
  const [perPerson, setPerPerson] = useState(false);
  const [pricePersons, setPricePersons] = useState([]);
  const [pricePerAll, setPricePerAll] = useState(0);
  const [defaultCurrency, setDefaultCurrency] = useState('');
  const [selectedLanguages, setSelectedLanguages] = useState([]);


  useEffect(() => {
    fetchDefaultCurrency();
  }, []);

  const fetchDefaultCurrency = async () => {
    let result = await axios.get(API_URL + 'api/guide/currency', {
      headers: {
        Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
      },
    });

    setDefaultCurrency(result.data);
  }
  const handleLanguageChange = (value) => {
    setSelectedLanguages(value);
  }
  const onFileChange = ({ fileList }) => {
    setFileList(fileList);
  };
  const props = {
    onRemove: (file) => {
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
    },
    beforeUpload: (file) => {
      return false;
    },
    fileList,
  };

  const handleLocationChange = (value) => {
    setLocation(value);
    errors.location = "";
  };
  const onFinish = async (values) => {
    console.log('Form values:', values);
    // if (languageId <= 0) {
    //   message.error(t("guideTourFrom.selectLanguage"));
    // }
    if (!form) {
      console.error('Form is not defined');
      return;
    }
    const validationErrors = {};

    errors.location = '';
    if (location.place_id.length === 0 || location.place_id === undefined) {
      validationErrors.location = t("guideTourFrom.selectLocation");

    }

    if (Object.keys(validationErrors).length > 0) {
      setErrors(validationErrors);
      return;
    }
    const createTourDefinitionDto = {
      title: JSON.stringify(values.Title),
      description: JSON.stringify(values.Description),
      region: location.label,
      lat: location.location.lat.toString(), // Konwersja na string
      lng: location.location.lng.toString(), // Konwersja na string                   
      placeId: location.place_id,
      descriptionShort: values.DescriptionShort ? JSON.stringify(values.DescriptionShort) : '',
      price: pricePerAll,
      currency: defaultCurrency,
      maxCapacity: values.MaxCapacity,
      languages: JSON.stringify(selectedLanguages),
      tourLenght: values.TourLenght,
      meetingPoint: JSON.stringify(values.MeetingPoint),
      perPerson: perPerson,
      perPersonPriceJson: pricePersons
    };
    const formData = new FormData();
    formData.append('createTourDefinitionDto', JSON.stringify(createTourDefinitionDto));



    fileList.forEach((file, index) => {
      formData.append('attachments', file.originFileObj);
    });

    try {
      await axios.post(API_URL + 'api/tourdefinitions', formData, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      message.success(t("message.saveOk"));
      navigate(-1);

    } catch (error) {
      console.error('Error creating tour definition:', error);
      message.error(t("message.error"));
    }
  };

  const goBack = () => {
    navigate(`/${lang}/guide/list`);
  }
  const handlePerPerson = (e) => {

    setPerPerson(e.target.value);
  };
  const handlePriceChange = (value) => {

    setPricePersons(JSON.stringify(value));
  };
  const handlePriceAllChange = (value) => {

    setPricePerAll(value);

  };


  // const handleDescriptionChange = (content) => {

  //   const updatedContent = Object.fromEntries(
  //     Object.entries(content).map(([key, value]) => [key, value.replace(/\n/g, '<br>')])
  //   );
  //   setDescription(updatedContent);
  // };


  return (

    <Form form={form} onFinish={onFinish} layout="vertical">
      <div style={{ marginTop: "10px", marginBottom: "10px" }} >
        <Button onClick={goBack} type="primary">
          {t("common.goBack")}
        </Button></div>
      <Row>
        <Col span={12}>
          <Form.Item name="location" label={t("guideTourFrom.location")}>
            <LocationAutosuggest onLocationChange={handleLocationChange} location={location} />
            {errors.location && (
              <span className="error-message">{errors.location}</span>
            )}
          </Form.Item>
        </Col>
      </Row>      <Row gutter={[16, 0]}>
        <Col span={12}>
          <Form.Item name="Title" label={t("guideTourFrom.title")} rules={[{ required: true }]}>
            <LanguageInput />

          </Form.Item>
        </Col>

      </Row>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <Form.Item name="DescriptionShort" label={t("guideTourFrom.descriptionShort")} rules={[{ required: true }]}>

            <LanguageInput />

          </Form.Item>
          <Form.Item name="MeetingPoint" label={t("guideTourFrom.meetingPoint")} rules={[{ required: true }]} >
            <LanguageInput />

          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <Form.Item name="Description" label={t("guideTourFrom.descriptionFull")} rules={[{ required: true }]} >
            <LanguageTextArea rows={4} />
            {/* <TextArea onChange={handleDescriptionChange} style={{ height: "200px" }} /> */}
          </Form.Item>
        </Col>
      </Row>
      <Row gutter={[16, 0]}>
        <Col span={12}>
          <Row>
            <Col span={12}>
              <Form.Item name="Language" label={t("common.language")} >
                <LanguageSelector value={selectedLanguages} onChange={handleLanguageChange} />
              </Form.Item>


              <Form.Item name="Price" label={t("guideTourList.price") + " " + defaultCurrency} >

                <Radio.Group onChange={handlePerPerson} value={perPerson}>
                  <Radio value={false}>{t('guideTourFrom.pricePerAtraction')}</Radio>
                  <Radio value={true}>{t('guideTourFrom.pricePerPerson')}</Radio>
                </Radio.Group>
                <div hidden={perPerson}><InputNumber onChange={handlePriceAllChange} value={pricePerAll} min={0} /></div>
                <div hidden={!perPerson}> <PriceComponent onPriceChange={handlePriceChange} dataSourceToSet={[]} /> </div>
              </Form.Item>

              {/* <p> <div>{t('guideTourFrom.perPerson')} </div> </p>              */}
              <Form.Item>
                <Button type="primary" htmlType="submit">
                  {t("common.save")}
                </Button>
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item
                name="MaxCapacity"
                label={t("guideTourList.maxcapacity")}
                rules={[
                  {
                    required: true,
                    message: t("guideTourList.maxcapacityRequired")
                  },
                  ({ getFieldValue }) => ({
                    validator(_, value) {
                      if (value > 0) {
                        return Promise.resolve();
                      }
                      return Promise.reject(new Error(t("guideTourList.mustBeGreaterThanZero")));
                    },
                  }),
                ]}
              >
                <InputNumber min={0} />
              </Form.Item>
              <Form.Item name="TourLenght" label={t("guideTourList.tourLenght")} rules={[{ required: true }]}>
                <InputNumber min={0} step="0.5" />
              </Form.Item>
            </Col>

            <Col span={12}>
              <Form.Item name="attachments" label={t("guideTourList.attachments")}>
                <Upload {...props} onChange={onFileChange}>
                  <Button icon={<UploadOutlined />}>{t("guideTourList.selectFiles")}</Button>
                </Upload>
              </Form.Item>
            </Col>
          </Row>

        </Col>

      </Row>


    </Form>

  );
};

export default GuideAddTourForm;
