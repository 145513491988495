import { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Container } from 'reactstrap';
import NavMenu from './NavMenu';
import Footer from "./Footer";
import CookieConsentComponent from "./CookieConsent.js";
import { Helmet } from "react-helmet";
import { useTranslation } from 'react-i18next';
const Layout = ({ children }) => {  // Receive lang as a prop
  const [token, setToken] = useState('');
  const { t , i18n } = useTranslation(); // Access i18n instance
  const lang = i18n.language; // Get the current language
  useEffect(() => {
   
    const storedToken = localStorage.getItem('accessToken');
    const tokenExpiration = localStorage.getItem('expirationDate') ? localStorage.getItem('expirationDate') : '';
    const currentDate = new Date(); // Current date and time

    if (storedToken && tokenExpiration) {
      var expirationDate = new Date(tokenExpiration);
      if (expirationDate < currentDate) {
        localStorage.removeItem('accessToken');
        localStorage.removeItem('expirationDate');
      } else {
        const oneHourLater = new Date(currentDate.getTime() + (1 * 60 * 60 * 1000)); // Add one hour

        localStorage.setItem('expirationDate', oneHourLater);
        setToken(storedToken);
      }
    }
  }, [lang]);

  return (
    <div style={{ display: "flex", flexDirection: "column", minHeight: "100vh" }}>
      <Helmet>
        <html lang={lang} />
      </Helmet>

      <NavMenu lang={lang} style={{ flex: "0 0 auto" }} />  {/* Pass lang to NavMenu */}
      <Container tag="main" style={{ fontFamily: "'PT Serif', serif", flex: 1, minHeight: 0 }}>
        <CookieConsentComponent />
        {children}
      </Container>
      <Footer style={{ flex: "0 0 auto" }} />
    </div>
  );
};

export default Layout;
