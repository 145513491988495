import React, { useState, useEffect } from 'react';
import { message, DatePicker, List,Input } from 'antd';
import { Container, Row } from 'react-bootstrap';
import axios from 'axios';
import API_URL from '../config.js';
import moment from 'moment';
import dayjs from 'dayjs';
import { Link } from 'react-router-dom';
import withAuth from './withAuth.js';
import { useTranslation } from 'react-i18next';
import DisplayTextComponent from "./DisplayTextComponent.js";
const ReservationsGuide = () => {
  const [dataSource, setDataSource] = useState([]);
  const { t, i18n } = useTranslation(); // Access i18n instance
  const lang = i18n.language; // Get the current language
  const [searchText, setSearchText] = useState(''); // stan dla pola tekstowego
  const [dateFrom, SetDateFrom] = useState(dayjs());
  const [dateTo, SetDateTo] = useState(dayjs().add(7, 'day'));
  useEffect(() => {
  
    


  
    fetchData();
  }, []);

  const fetchData = async () => {

    try {
      const dateTimeFrom = moment(`${moment(new Date(dateFrom)).format('YYYY-MM-DD')} 00:00:00`, 'YYYY-MM-DD HH:mm').format(
        'YYYY-MM-DDTHH:mm:ss');

    const dateTimeTo = moment(`${moment(new Date(dateTo)).format('YYYY-MM-DD')} 23:59:59`, 'YYYY-MM-DD HH:mm').format(
        'YYYY-MM-DDTHH:mm:ss');
      let url = API_URL + 'api/Guide/Reservations?from='+dateTimeFrom+'&to='+dateTimeTo+'&searchText='+searchText;
      const response = await axios.get(url, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      });
      const data = response.data;
      setDataSource(data);
    } catch (error) {

      message.error(t("message.error"));
    }
  };
 
 
  const getStatus = (item) => {
    if (item.resignationAt !== null) {
      return t("reservations.statusResignation");
    }
    if (item.isCanceled) {
      return t("reservations.statusCancelled");
    }
    if (item.paidStatus) {
      return t("reservations.statusPaid");
    } else {
      return t("reservations.statusNotPaid");

    }

    // return item.resignationAt === null ? item.paidStatus ? t("reservations.statusPaid") : t("reservations.statusNotPaid") : t("reservations.statusResignation");
  };
  const handleSearch = (e) => {
    e.preventDefault();
    fetchData();
   };
  return (
    <div className="overflow-x-hidden">
      <Container style={{ color: "rgb(255, 255, 255)", height: "1000px" }}>
      <form className="search-form" onSubmit={handleSearch}>
      <div className="text-input">
        <Input
          type="text"
          value={searchText}
          onChange={(e) => setSearchText(e.target.value)}
          placeholder="Email or reservation number"
          style={{ padding: '6px', borderRadius: '4px', width: '100%',marginTop:'10px' }}
        />
      </div>
      <div className="date-picker">
                <DatePicker
                    id="date-from"
                    value={dateFrom}
                    onChange={(date) => SetDateFrom(date)}
                    dateFormat="yyyy-MM-dd"
                    placeholder={t("common.dateFrom")}

                />
            </div>
            <div className="date-picker">
                <DatePicker
                    id="date-to"
                    value={dateTo}
                    onChange={(date) => SetDateTo(date)}
                    dateFormat="yyyy-MM-dd"
                    placeholder={t("common.dateTo")}
                />
            </div>

            <button className="button primary xl w-full md:w-max" style={{ background: 'rgb(255, 152, 0)', height: '40px', borderRadius: '8px', color: 'rgb(17, 24, 39)', border: '2px solid rgb(255, 152, 0)' }} type="submit">{t('common.searchText')}</button>
       </form>
        <div style={{ maxWidth: "1200px", margin: "auto", paddingTop: "30px" }}>
           <List className='mobile-hidden' style={{ transition: 'background 0.3s' }}
            itemLayout="vertical"
            size="large"
            dataSource={dataSource}
            renderItem={(item, index) => (

              <List.Item
                key={item.tourId}
                extra={
                  
                  <div width={272}>
                    <div>{item.reservationNumber}</div>
                     {t("common.price")}: {item.price.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {item.currency}
                    <div>{t("reservations.paid")}: {item.paidAmount.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {item.currency}</div>
                    <div>
                      Status : {getStatus(item)}</div>
                    <div>{t("reservations.persons")} : {item.personsCount}</div>    
                  </div>
                }
              >
                <List.Item.Meta
                  avatar={<img hidden={item.mainImage == undefined}
                    width={100}
                    alt="logo"
                    src={item.mainImage == null ? '../img/empty.jpg' : API_URL + 'images/tours/' + item.tourDefinitionId + "/thumb/thumb_" + item.mainImage}
                  />}
                  title={<a href={`/${lang}/tours/` + item.tourDefinitionId + "/"+item.region} > <DisplayTextComponent data={item.title}/></a>}
                  description={item.region}
                />
                <div> {t("reservations.date")} : {moment(item.date).format('YYYY-MM-DD HH:mm')} </div>
                <div> {t("common.client")} : {item.client} </div>
                {/* <div> {t("common.language")} : {item.language} </div> */}
                {/* {item.date} */}
                {/* </div> */}
              </List.Item>

            )}
          />
          <List className='desktop-hidden' style={{ transition: 'background 0.3s' }}
            itemLayout="vertical"
            size="large"
            dataSource={dataSource}
            renderItem={(item, index) => (

              <List.Item
                key={item.tourId}
              // extra={

              // }
              >
                <List.Item.Meta
                  avatar={<img
                    width={100}
                    alt="logo"
                    src={item.mainImage == null ? '../img/empty.jpg' : API_URL + 'images/tours/' + item.tourDefinitionId + "/thumb/thumb_" + item.mainImage}

                  />}
                  title={<a href={`/${lang}/tours/` + item.tourDefinitionId+ "/"+item.region} ><DisplayTextComponent data={item.title} /></a>}
                  description={item.region}

                />
                <div>{item.reservationNumber}</div>

                <div> {t("reservations.date")} : {moment(item.date).format('YYYY-MM-DD HH:mm')} </div>
                <div> {t("common.guide")} : {item.guide} </div>
                <div> {t("common.phone")} : {item.phoneNumber} </div>
                {/* <div> {t("common.language")} : {item.language} </div> */}
                <div width={272}> {t("common.price")}: {item.price.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {item.currency}
                  <div>{t("reservations.paid")}: {item.paidAmount.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {item.currency}</div>
                  <div>Status : {item.resignationAt === null ? item.paidStatus ? t("reservations.statusPaid") : t("reservations.statusNotPaid") : t("reservations.statusResignation")}</div>
                  <div>{t("reservations.persons")} : {item.personsCount}</div>                  
                </div>                
              </List.Item>
            )}
          />
        </div>
      </Container >
    </div >
  );
};
export default withAuth(ReservationsGuide);
