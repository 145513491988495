import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
const NotFound = () => {
    const { t, i18n } = useTranslation(); // Access i18n instance
    const lang = i18n.language; // Get the current language
   
    return (
        <Container style={{backgroundColor: 'rgb(51, 51, 51)',color:"rgb(255, 255, 255)",height:"1000px" }}>
        <div style={{ textAlign: 'center' }}>
            <h1>404 - Page Not Found</h1>
            <p>Oops! The page you're looking for does not exist.</p>
            <Link to={"/"+lang}>Go back to the homepage</Link>
            </div>
        </Container>
    );
};

export default NotFound;
