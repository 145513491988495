const DisplayTextHelper = ({ data }) => {
  // Funkcja do pobrania języka przeglądarki
  const getBrowserLanguage = () => {
    const language = navigator.language || navigator.userLanguage;
    return language.split('-')[0].toUpperCase(); // zwraca np. "EN" z "en-US"
  };

  let parsedData = {};

  // Funkcja sprawdzająca, czy 'data' jest poprawnym JSON-em
  const isJSON = (data) => {
    try {
      parsedData = JSON.parse(data);
      return true;
    } catch (e) {
      return false;
    }
  };

  // Jeśli 'data' nie jest JSON-em, zwróć je bez zmian
  if (!isJSON(data)) {
    return data;
  }

  // Funkcja do pobrania treści w odpowiednim języku
  const getContentByLanguage = (language) => {
    if (!language) {
      // Jeśli język nie jest podany, szukaj wartości dla "EN"
      const contentEN = parsedData.EN;
      if (contentEN !== undefined && contentEN !== '') {
        return contentEN;
      }

      // Jeśli "EN" jest pusty, znajdź pierwszą niepustą wartość
      const entries = Object.entries(parsedData);
      for (let [key, value] of entries) {
        if (value !== undefined && value !== '') {
          return value;
        }
      }
      return '';
    }

    // Spróbuj znaleźć wartość dla podanego języka
    const content = parsedData[language];
    if (content !== undefined && content !== '') {
      return content;
    } else {
      // Jeśli wartość dla podanego języka jest pusta, spróbuj znaleźć wartość dla "EN"
      const contentEN = parsedData.EN;
      if (contentEN !== undefined && contentEN !== '') {
        return contentEN;
      }

      // Jeśli "EN" jest pusty, znajdź pierwszą niepustą wartość
      const entries = Object.entries(parsedData);
      for (let [key, value] of entries) {
        if (value !== undefined && value !== '') {
          return value;
        }
      }
      return '';
    }
  };

  // Pobierz treść w języku przeglądarki
  const content = getContentByLanguage(getBrowserLanguage());

  return content;
};

export default DisplayTextHelper;
