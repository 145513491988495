import React from 'react';

const SelectedLanguagesDisplay = ({ selectedLanguages = '[]' }) => {
  let parsedLanguages;

  try {
    parsedLanguages = JSON.parse(selectedLanguages);
  } catch (e) {
    console.error('Invalid JSON string for selectedLanguages:', selectedLanguages);
    parsedLanguages = [];
  }

  const languages = [
    {
      value: 'PL',
      label: 'Polski',
      flag: 'https://flagcdn.com/w320/pl.png',
    },
    {
      value: 'EN',
      label: 'English',
      flag: 'https://flagcdn.com/w320/gb.png',
    },
    {
      value: 'DE',
      label: 'Deutch',
      flag: 'https://flagcdn.com/w320/de.png',
    },
    {
      value: 'ES',
      label: 'Spanish',
      flag: 'https://flagcdn.com/w320/es.png',
    },
    {
      value: 'RU',
      label: 'Russian',
      flag: 'https://flagcdn.com/w320/ru.png',
    },
  ];

  return (
    <span style={{ display: 'flex', gap: '10px', marginTop: '5px', marginLeft:'5px' }}>
      {parsedLanguages.map(languageCode => {
        const language = languages.find(lang => lang.value === languageCode);
        return language ? (
          <img
            key={language.value}
            src={language.flag}
            alt={language.label}
            style={{ width: 30, height: 20, border: '1px solid black' }}
          />
        ) : null;
      })}
    </span>
  );
};

export default SelectedLanguagesDisplay;
