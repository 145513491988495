import React, { useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import '../../blog.css';
const Blog = () => {
    const { t, i18n } = useTranslation(); // Access i18n instance
    const lang = i18n.language; // Get the current language


    return (

        <Container style={{ color: "rgb(0, 0, 0)", paddingTop: "10px", fontFamily: "PT Serif" }}>
            <Helmet>
      <html lang={lang} />
      <meta charSet="utf-8" />
                <meta name="description" content={t("homepage.descriptionTag")} />
                <meta name="keywords" content={t("homepage.keyWords")} />
                <title>Najlepsze Atrakcje Turystyczne - Blog</title>
                <link rel="canonical" href={`https://tourpilot.eu/${lang}/blog`} />
                <link rel="alternate" href="https://tourpilot.eu/pl/blog" hreflang="pl" />
                <link rel="alternate" href="https://tourpilot.eu/en/blog" hreflang="en" />
                <link rel="alternate" href="https://tourpilot.eu/pl/blog" hreflang="x-default" />
            </Helmet>
            <div className='blog'>
                <h2 style={{ textAlign: "center" }}>Najnowsze wpisy</h2>

                <div className='blog-card-container '>
                <div className="blog-card-locations"  >
                        <img src="img/poznan.jpg" alt="Przewodnik Poznań" />
                        <h2><a href={`/${lang}/blog/poznan-atrakcje`} >Poznań Atrakcje</a></h2>
                        <p>
                        perła Wielkopolski, która zachwyca zabytkami, malowniczymi uliczkami i dynamiczną sceną kulturalną
                        </p>
                        <span>Data publikacji : 30.09.2024 </span>

                    </div>
                    
                    <div className="blog-card-locations"  >
                        <img src="img/gdansk.jpg" alt="Przewodnik Gdańsk" />
                        <a href={`/${lang}/blog/gdansk-atrakcje`} ><h2>Gdańsk Atrakcje</h2></a>
                        <p>
                            Gdańsk, jedno z najstarszych miast Polski, oferuje wiele atrakcji w Gdańsku. Warto zwiedzić starówkę, zobaczyć Żuraw nad Motławą oraz Westerplatte.
                        </p>
                        <span>Data publikacji : 06.06.2024 </span>

                    </div>

                    <div className="blog-card-locations"  >
                        <img src="img/warszawa.jpg" alt="Przewodnik Warszawa" />
                        <h2><a href={`/${lang}/blog/warszawa-atrakcje`} >Warszawa Atrakcje</a></h2>
                        <p>
                            Stolica Polski to dynamiczne miasto, gdzie nowoczesne wieżowce sąsiadują z zabytkowymi budowlami. Koniecznie odwiedź Stare Miasto, Łazienki Królewskie oraz Muzeum Powstania Warszawskiego
                        </p>
                        <span>Data publikacji : 01.06.2024 </span>

                    </div>

                    <div className="blog-card-locations"  >
                        <img src="img/wroclaw.jpg" alt="Przewodnik Wrocław" />
                        <h2><a href={`/${lang}/blog/wroclaw-atrakcje`} >Wrocław Atrakcje</a></h2>
                        <p>
                            Spacer po Ostrowie Tumskim, wizyta w Zoo oraz Hala Stulecia to tylko niektóre z atrakcji we Wrocławiu, które czekają na turystów.
                        </p>
                        <span>Data publikacji : 16.05.2024 </span>

                    </div>

                    <div className="blog-card-locations"  >
                        <img src="img/zakopane.jpg" alt="Przewodnik Zakopane" />
                        <h2><a href={`/${lang}/blog/zakopane-atrakcje`}>Zakopane Atrakcje</a></h2>
                        <p>
                            Krupówki, Gubałówka i Morskie Oko to najważniejsze atrakcje w Zakopanem, które warto zobaczyć.
                        </p>
                        <span>Data publikacji : 10.05.2024 </span>

                    </div>

                   
                   

                    <div className="blog-card-locations"  >
                        <img src="img/karkow.jpg" alt="Przewodnik Kraków" />
                        <h2><a href={`/${lang}/blog/krakow-atrakcje`} >Kraków Atrakcje</a></h2>
                        <p>
                            Wawel, Rynek Główny oraz Kazimierz to tylko niektóre z atrakcji w Krakowie, które przyciągają turystów .
                        </p>
                        <span>Data publikacji : 21.05.2024 </span>

                    </div>
                    <div className="blog-card-locations"  >
                        <img src="img/mazury.jpg" alt="Przewodnik Mazury" />
                        <h2><a href={`/${lang}/blog/mazury-atrakcje`} >Mazury Atrakcje</a></h2>
                        <p>
                            Żeglarstwo, kajakarstwo oraz relaks nad jeziorami to tylko niektóre z atrakcji na Mazurach.
                        </p>
                        <span>Data publikacji : 04.05.2024 </span>

                    </div>
                    


                    <br />
                </div>
            </div>
        </Container >

    );
};
export default Blog;
