import React, { useState, useEffect } from 'react';
import { Button, Checkbox, Modal } from 'antd';
import { useTranslation } from 'react-i18next';
import './../cookieConsent.css';


const CookieConsent = () => {
  const { t, i18n } = useTranslation(); // Access i18n instance
    const lang = i18n.language; // Get the current language
  const [visible, setVisible] = useState(false);
  const [showPreferences, setShowPreferences] = useState(false);
  const [preferences, setPreferences] = useState({
    necessary: true,
    functional: false,
    analytics: false,
    advertising: false
  });

  useEffect(() => {
    const consent = localStorage.getItem('cookieConsent');
    if (!consent) {
      setVisible(true);
    } else {
      const savedPreferences = JSON.parse(localStorage.getItem('cookiePreferences'));
      setPreferences(savedPreferences);
      updateGoogleConsent(savedPreferences);
    }
  }, []);

  const handleAcceptAll = () => {
    const newPreferences = {
      necessary: true,
      functional: true,
      analytics: true,
      advertising: true
    };
    setPreferences(newPreferences);
    localStorage.setItem('cookieConsent', 'accepted');
    localStorage.setItem('cookiePreferences', JSON.stringify(newPreferences));
    setVisible(false);
    setShowPreferences(false);
    updateGoogleConsent(newPreferences);
  };

  const handleDeclineAll = () => {
    const newPreferences = {
      necessary: true,
      functional: false,
      analytics: false,
      advertising: false
    };
    setPreferences(newPreferences);
    localStorage.setItem('cookieConsent', 'declined');
    localStorage.setItem('cookiePreferences', JSON.stringify(newPreferences));
    setVisible(false);
    setShowPreferences(false);
    updateGoogleConsent(newPreferences);
  };

  const handleSavePreferences = () => {
    localStorage.setItem('cookieConsent', 'custom');
    localStorage.setItem('cookiePreferences', JSON.stringify(preferences));
    setShowPreferences(false);
    updateGoogleConsent(preferences);
  };

  const updateGoogleConsent = (preferences) => {
    if (window.gtag) {
      window.gtag('consent', 'update', {
        ad_storage: preferences.advertising ? 'granted' : 'denied',
        analytics_storage: preferences.analytics ? 'granted' : 'denied'
      });
    }
  };

  const handleOpenPreferences = () => {
    setShowPreferences(true);
    setVisible(false);
  };

  const handlePreferenceChange = (type) => {
    setPreferences((prevPreferences) => ({
      ...prevPreferences,
      [type]: !prevPreferences[type]
    }));
  };

  return (
    <>
      {visible && (
        <div className="cookie-consent">
          <div className="cookie-consent__content">
            <div>{t("cookieConsent.bannerText")}</div>
            <div className="cookie-consent__actions">
              <Button onClick={handleAcceptAll} type="primary" className="cookie-consent__button">
                {t("cookieConsent.acceptAll")}
              </Button>
              <Button onClick={handleDeclineAll} type="default" className="cookie-consent__button">
                {t("cookieConsent.declineAll")}
              </Button>
              <Button onClick={handleOpenPreferences} type="default" className="cookie-consent__button">
                {t("cookieConsent.preferences")}
              </Button>
            </div>
          </div>
        </div>
      )}
      <Modal
        title={t("cookieConsent.preferencesTitle")}
        visible={showPreferences}
        onCancel={() => setShowPreferences(false)}
        footer={[
          <Button key="save" type="primary" onClick={handleSavePreferences}>
            {t("cookieConsent.savePreferences")}
          </Button>,
          <Button key="decline" onClick={handleDeclineAll}>
            {t("cookieConsent.declineAll")}
          </Button>,
          <Button key="close" onClick={() => setShowPreferences(false)}>
            {t("cookieConsent.close")}
          </Button>
        ]}
      >
        <p>{t("cookieConsent.preferencesDescription")}</p>
        <div className="preferences-center__options">
          <Checkbox
            checked={preferences.necessary}
            onChange={() => handlePreferenceChange('necessary')}
            disabled
          >
            {t("cookieConsent.necessary")}
          </Checkbox>
          <Checkbox
            checked={preferences.functional}
            onChange={() => handlePreferenceChange('functional')}
          >
            {t("cookieConsent.functional")}
          </Checkbox>
          <Checkbox
            checked={preferences.analytics}
            onChange={() => handlePreferenceChange('analytics')}
          >
            {t("cookieConsent.analytics")}
          </Checkbox>
          <Checkbox
            checked={preferences.advertising}
            onChange={() => handlePreferenceChange('advertising')}
          >
            {t("cookieConsent.advertising")}
          </Checkbox>
        </div>
      </Modal>
    </>
  );
};

export default CookieConsent;
