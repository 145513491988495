import React, { useState, useEffect } from 'react';
import API_URL from '../config';
import axios from 'axios';
import { message } from 'antd';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const PaymentSuccess = () => {  
  const { t, i18n } = useTranslation(); // Access i18n instance
  const lang = i18n.language; // Get the current language
    const navigate = useNavigate();
    useEffect(() => {
        setPaymentSuccesfull();
    }, []);

    const setPaymentSuccesfull = async () => {
        const urlParams = new URLSearchParams(window.location.search);
        const sessionId = urlParams.get('sessionId');
        let url = API_URL + 'api/Checkout/success?sessionId='+sessionId;
        try{
        const response = await axios.post(url,null, {
            headers: {
              Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
            },
          });
        navigate(`/${lang}/reservations`);
        
    
        }catch(error)
        {
        message.error(t("message.error"));
        }
    }
  

};


export default PaymentSuccess;
