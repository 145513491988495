import React from 'react';
import { Table } from 'antd';
import API_URL from '../config.js';
import { useTranslation } from 'react-i18next';
import './../toursListComponent.css'; // Importujemy plik CSS dla wersji desktopowej
import DisplayTextComponent from "./DisplayTextComponent.js";
const ToursCardListMobileComponent = ({ dataSource}) => {
    const { t , i18n } = useTranslation(); // Access i18n instance
    const lang = i18n.language; // Get the current language

    const getPrices = (item) => {
        if (item.perPerson) {
            try {
                const dataArray = JSON.parse(item.perPersonPriceJson);
                return dataArray.map(row => {
                    const formattedPrice = row.price.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                    return `${translateType(row.name)} (${row.ageFrom}-${row.ageTo}) - ${formattedPrice} ${item.currency}`;
                }).join('<br />')

            } catch (error) {
                console.error('Invalid JSON format', error);
            }

        } else {
            return item.price + " " + item.currency;
        }
    };

   

    const translateType = (value) => {
        if (value === "Adult") {
            return t("priceComponent.adult")
        } else if ("Kid") {
            return t("priceComponent.kid")
        }
    };

    const data = dataSource.map((item, index) => ({
        key: item.id,
        ...item
    }));

    return (
        <div className='card-container-mobile'>
          {dataSource.map((item, index) => (
            <a href={`${lang}/tours/${item.id}/${item.region}`} className="card" key={index}>
              <img src={item.mainImage == null ? '../img/empty.jpg' : `${API_URL}images/tours/${item.id}/thumb/thumb_${item.mainImage}`} alt={<DisplayTextComponent data={item.title}/>} />
              <p><DisplayTextComponent data={item.title}/></p>
              <p> <span dangerouslySetInnerHTML={{ __html: getPrices(item) }} /></p>
            </a>
          ))}
        </div>
      );
};

export default ToursCardListMobileComponent;
