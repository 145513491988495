import SearchForm from './SearchForm';
import { useNavigate } from "react-router-dom";
import { useTranslation } from 'react-i18next';
import ToursListMainPage from "./ToursListMainPage.js";
import { Helmet } from "react-helmet";
import ToursCardListComponent from './ToursCardListComponent';
import ToursCardListMobileComponent from './ToursCardListMobileComponent';
import { useMediaQuery } from 'react-responsive';
import React, { useState, useEffect, Component } from 'react';
import axios from 'axios';
import API_URL from '../config';
import { message } from 'antd';

const Home = () => {
    const [dataSource, setDataSource] = useState([]);
    const { t , i18n } = useTranslation(); // Access i18n instance
    const lang = i18n.language; // Get the current language
    const dateFrom = new Date();
    const dateTo = new Date(dateFrom.getTime() + (7 * 24 * 60 * 60 * 1000));
    const handleSearchSubmit = (selectedLocation, dateTimeFrom, dateTimeTo) => {
        window.location.href = `/${lang}/attractions/` + selectedLocation.label + "?from=" + dateTimeFrom + "&to=" + dateTimeTo;
    };
    const urlCard1 = lang+`/attractions/Gdańsk`;
    const urlCard2 = lang+`/attractions/Kraków`;
    const urlCard3 = lang+`/attractions/Warszawa`;
    const urlCard4 = lang+`/attractions/Zakopane`;
    const urlCard5 = lang+`/attractions/Wrocław`;
    const urlCard6 = lang+`/attractions/Poznań`;
    const isDesktop = useMediaQuery({ minWidth: 768 });
    useEffect(() => {
        fetchData();


    }, []);

    const fetchData = async () => {

        try {

            let url = API_URL + 'api/Tours/mainpage/?&skip=0&take=6';

            const response = await axios.get(url);
            const data = response.data;
            setDataSource(data);

        } catch (error) {
            console.error('Error creating tour definition:', error);
            message.error(t("message.error"));
        } finally {

        }
    };
    return (


        <div className="overflow-x-hidden">
            <section className="flex-shrink-0 relative flex items-center min-h-120" style={{
                backgroundImage: 'url(img/photo-1.jpg)',
                backgroundPosition: 'center center',
                backgroundSize: 'cover'
            }}>
                <Helmet>
                    <meta charSet="utf-8" />  
                    <html lang={lang} />
                    <meta name="description" content={t("homepage.descriptionTag")} />
                    <meta name="keywords" content={t("homepage.keyWords")} />
                    <title>{t('homepage.title')}</title>                    
                    <link rel="canonical" href={`https://tourpilot.eu/${lang}`} />
                    <link rel="alternate" href="https://tourpilot.eu/pl" hreflang="pl" />
                    <link rel="alternate" href="https://tourpilot.eu/en" hreflang="en" />
                    <link rel="alternate" href="https://tourpilot.eu/pl" hreflang="x-default" />
                </Helmet>

                <div className="absolute inset-0" style={{ backgroundColor: 'rgba(0, 0, 0, 0.7)' }}></div>

                <div className="relative container mx-auto px-6 z-10 py-12 lg:py-32 xl:py-40 ">
                    <SearchForm handleSearchSubmit={handleSearchSubmit} dateFromParam={dateFrom.toISOString().slice(0, 19).replace('T', ' ')} dateToParam={dateTo.toISOString().slice(0, 19).replace('T', ' ')} />

                    <div className="max-w-4xl text-left ml-0 m-auto text-8x">
                        <h1 className="heading-large mb-6 break-word text-center" style={{
                            color: 'rgb(255, 255, 255)',
                            fontFamily: '"Rufina", serif',
                            fontWeight: 700
                        }}>{t('homepage.greeting')}</h1>
                        <p className="body-large" style={{
                            color: 'rgb(255, 255, 255)',
                            fontFamily: '"PT Serif", serif',
                            fontWeight: 400
                        }}>{t('homepage.description')}</p>
                    </div>
                </div>
            </section>
            <section
      className="flex-shrink-0"
      style={{ backgroundColor: 'rgb(34, 34, 34)' }}
    >
      {isDesktop ? (
        // Desktop View
        <div>
          <h2
            className="heading-large mb-6 text-center"
            style={{
              color: 'rgb(255, 255, 255)',
              fontFamily: "'Rufina', serif",
              fontWeight: 700,
            }}
          >
            {t('homepage.mostpopularTitle')}
           
          </h2>
          <ToursCardListComponent dataSource={dataSource} />
        </div>
      ) : (
        // Mobile View
        <div>
          <h2
            className="heading-medium mb-6 text-center"
            style={{
              color: 'rgb(255, 255, 255)',
              fontFamily: "'Rufina', serif",
              fontWeight: 700,
              paddingTop: 10,
            }}
          >
            {t('homepage.mostpopularTitle')}
          </h2>
          <ToursCardListMobileComponent dataSource={dataSource} />
        </div>
      )}
    </section>

    {isDesktop ? (
    <section className="flex-shrink-0 mobile-hidden" style={{ backgroundColor: 'rgb(34, 34, 34)' }} >
    <h2 className="heading-large mb-6 text-center" style={{
        color: 'rgb(255, 255, 255)',
        fontFamily: "'Rufina', serif",
        fontWeight: 700
    }}> {t('homepage.mostpopularDestinations')}</h2>

    <div className='card-container'>
        <a href={urlCard1} className="card-locations"  >
            <img src="img/gdansk.jpg" alt="Przewodnik Gdańsk" />
            <p>Gdańsk {t('homepage.attractions')}</p>
        </a>

        <a href={urlCard2} className="card-locations" >
            <img src="img/karkow.jpg" alt="Przewodnik Kraków" />
            <p>Kraków {t('homepage.attractions')}</p>
        </a>
        <a href={urlCard3} className="card-locations" >
            <img src="img/warszawa.jpg" alt="Przewodnik Warszawa" />
            <p>Warszawa {t('homepage.attractions')}</p>
        </a>
        <a href={urlCard4} className="card-locations">
            <img src="img/zakopane.jpg" alt="Przewodnik Zakopane" />
            <p>Zakopane {t('homepage.attractions')}</p>
        </a>
        <a href={urlCard5} className="card-locations" >
            <img src="img/wroclaw.jpg" alt="Przewodnik Wrocław" />
            <p>Wrocław {t('homepage.attractions')} </p>
        </a>
        <a href={urlCard6} className="card-locations" >
            <img src="img/poznan.jpg" alt="Przewodnik Poznań" />
            <p>Poznań {t('homepage.attractions')} </p>
        </a>

    </div>

</section>
    ) : (
        // Mobile View
        <section className="flex-shrink-0 desktop-hidden" style={{ backgroundColor: 'rgb(34, 34, 34)' }} >
        <h2 className="heading-medium mb-6 text-center" style={{
            color: 'rgb(255, 255, 255)',
            fontFamily: "'Rufina', serif",
            fontWeight: 700
        }}>{t('homepage.mostpopularDestinations')}</h2>

        <div className='most-popular-list-mobile'>
            <a href={urlCard1}   >
                
                <p>Gdańsk {t('homepage.attractions')}</p>
            </a>

            <a href={urlCard2}  >
                
                <p>Kraków {t('homepage.attractions')}</p>
            </a>
            <a href={urlCard3} >
               
                <p>Warszawa {t('homepage.attractions')}</p>
            </a>
            <a href={urlCard4} >
               
                <p>Zakopane {t('homepage.attractions')}</p>
            </a>
            <a href={urlCard5} >
                
                <p>Wrocław {t('homepage.attractions')} </p>
            </a>
            <a href={urlCard6}>
                
                <p>Poznań {t('homepage.attractions')} </p>
            </a>

        </div>

    </section>

    )}

          
        
         

            <section className="flex-shrink-0" style={{ backgroundColor: 'rgb(34, 34, 34)' }}>
                <div className="container mx-auto py-12 lg:py-14 xl:py-20">
                    <h2 className="heading-large mb-6 text-center"
                        style={{ color: 'rgb(255, 255, 255)', fontFamily: "'Rufina', serif", fontWeight: 700 }}>
                        {t("homepage.joinguide")}</h2>
                    <div className="flex flex-col sm:flex-row flex-wrap gap-10 justify-center">
                        <span className="hidden justify-center justify-end justify-start"></span>
                        <div className="block-list-item w-full">
                            <div className="flex-shrink-0 relative mb-6 aspect-w-16 aspect-h-9">
                                <span style={{
                                    boxSizing: 'border-box',
                                    display: 'block',
                                    overflow: 'hidden',
                                    width: 'initial',
                                    height: 'initial',
                                    background: 'none',
                                    opacity: 1,
                                    border: '0px none',
                                    margin: '0px',
                                    padding: '0px',
                                    position: 'absolute',
                                    inset: '0px'
                                }}>
                                    <img alt="Przewodnik turystyczny" src="img/guide2.webp" className="rounded-lg md:rounded-xl lg:rounded-2xl"
                                        style={{
                                            position: 'absolute',
                                            inset: '0px',
                                            boxSizing: 'border-box',
                                            padding: '0px',
                                            border: 'medium none',
                                            margin: 'auto',
                                            display: 'block',
                                            width: '0px',
                                            height: '0px',
                                            minWidth: '100%',
                                            maxWidth: '100%',
                                            minHeight: '100%',
                                            maxHeight: '100%',
                                            objectFit: 'cover',
                                            objectPosition: 'center center'
                                        }} /><noscript></noscript>
                                </span>
                            </div>
                            <div className="text-center">
                                <p className="heading-medium mb-4" style={{
                                    color: 'rgb(255, 255, 255)',
                                    fontFamily: "'Rufina', serif",
                                    fontWeight: 700
                                }}> {t('homepage.newOportunities')}</p>
                                <div className="rich-text-block" style={{ color: 'rgb(255, 255, 255)' }}>
                                    {t('homepage.newOportunitiesDesc')}

                                </div>
                            </div>
                        </div>
                        <div className="block-list-item w-full">
                            <div className="flex-shrink-0 relative mb-6 aspect-w-16 aspect-h-9">
                                <span style={{
                                    boxSizing: 'border-box',
                                    display: 'block',
                                    overflow: 'hidden',
                                    width: 'initial',
                                    height: 'initial',
                                    background: 'none',
                                    opacity: 1,
                                    border: '0px none',
                                    margin: '0px',
                                    padding: '0px',
                                    position: 'absolute',
                                    inset: '0px'
                                }}>
                                    <img alt="Zostań przeowdnikiem turystycznym" src="img/guide1.webp" className="rounded-lg md:rounded-xl lg:rounded-2xl"
                                        style={{
                                            position: 'absolute',
                                            inset: '0px',
                                            boxSizing: 'border-box',
                                            padding: '0px',
                                            border: 'medium none',
                                            margin: 'auto',
                                            display: 'block',
                                            width: '0px',
                                            height: '0px',
                                            minWidth: '100%',
                                            maxWidth: '100%',
                                            minHeight: '100%',
                                            maxHeight: '100%',
                                            objectFit: 'cover',
                                            objectPosition: 'center center'
                                        }} /><noscript></noscript>
                                </span>
                            </div>
                            <div className="text-center">
                                <p className="heading-medium mb-4" style={{
                                    color: 'rgb(255, 255, 255)',
                                    fontFamily: "'Rufina', serif",
                                    fontWeight: 700
                                }}>{t('homepage.businessInfo')}</p>
                                <div className="rich-text-block" style={{ color: 'rgb(255, 255, 255)' }}>

                                    {t('homepage.businessInfoDesc')}
                                </div>
                            </div>
                        </div>
                        <div className="block-list-item w-full">
                            <div className="flex-shrink-0 relative mb-6 aspect-w-16 aspect-h-9">
                                <span style={{
                                    boxSizing: 'border-box',
                                    display: 'block',
                                    overflow: 'hidden',
                                    width: 'initial',
                                    height: 'initial',
                                    background: 'none',
                                    opacity: 1,
                                    border: '0px none',
                                    margin: '0px',
                                    padding: '0px',
                                    position: 'absolute',
                                    inset: '0px'
                                }}>
                                    <img alt="Atrakcje turystyczne GetYourGuide" src="img/guide3.webp" decoding="async" data-nimg="fill"
                                        className="rounded-lg md:rounded-xl lg:rounded-2xl" style={{
                                            position: 'absolute',
                                            inset: '0px',
                                            boxSizing: 'border-box',
                                            padding: '0px',
                                            border: 'medium none',
                                            margin: 'auto',
                                            display: 'block',
                                            width: '0px',
                                            height: '0px',
                                            minWidth: '100%',
                                            maxWidth: '100%',
                                            minHeight: '100%',
                                            maxHeight: '100%',
                                            objectFit: 'cover',
                                            objectPosition: 'center center'
                                        }} /><noscript></noscript>
                                </span>
                            </div>
                            <div className="text-center">
                                <p className="heading-medium mb-4" style={{
                                    color: 'rgb(255, 255, 255)',
                                    fontFamily: "'Rufina', serif",
                                    fontWeight: 700
                                }}>{t('homepage.promo')}</p>
                                <div className="rich-text-block" style={{ color: 'rgb(255, 255, 255)', textAlign:"left" }}>
                                 <span dangerouslySetInnerHTML={{ __html: t('homepage.promoDesc') }}/>
                                    
                                </div>
                            </div>
                        </div>
                    </div>
                    <a className="button primary xl mt-6 lg:mt-8 w-full md:w-max" target="_self" style={{
                        background: 'rgb(255, 152, 0)',
                        borderRadius: '8px',
                        color: 'rgb(17, 24, 39)',
                        border: '2px solid rgb(255, 152, 0)'
                    }}
                        href="https://tourpilot.eu/register/guide">{t('login.joinUs')}</a>

                </div>
            </section>


        </div>
    );
};

export default Home;
