import React, {useState} from 'react';
import API_URL from '../config';
import { message } from 'antd';
import { useTranslation } from 'react-i18next';

export const ResetPasswordFrom = ({ resettoken }) => {
    const [password, setPassword] = useState('');
    const { t, i18n } = useTranslation(); // Access i18n instance
    const lang = i18n.language; // Get the current language
    const [confirmPassword, setConfirmPassword] = useState('');
      const handleSubmit = async (e) => {
        e.preventDefault();

        // Walidacja haseł
        if (password.length < 8 || !/(?=.*\d)(?=.*\W)/.test(password)) {
            message.error(t("login.passworRequirments"));
            return;
        }

        if (password !== confirmPassword) {
            message.error("login.passwordDoesntMatch");
            return;
        }

        // Utwórz obiekt z danymi
        const data = {
            token : resettoken,           
            password           
        };

        try {
            // Wyślij dane do API
            const response = await fetch(API_URL+'api/auth/password/reset', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(data)
            });

            if (response.ok) {             
                message.success(t("changePassword.passwordchanged"));
                window.location.href = `/${lang}`;
            } else {
                // Obsłuż błąd rejestracji
                message.error(t("message.error"));
            }
        } catch (error) {
            message.error(t("message.error"));
        }
    };

    return (
        <form className="form-container" onSubmit={handleSubmit}>
        <h3>{t("changePassword.title")}</h3>         
        <div>
            <label>{t("login.password")}:</label>
            <input
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required
            />
        </div>
        <div>
            <label>{t("login.confrimPassword")}:</label>
            <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
            />
        </div>
        <button type="submit">{t("common.save")}</button>
       
    </form>
    );
};

  export default ResetPasswordFrom;

