import { useEffect } from 'react';
import { useParams, useNavigate,useLocation  } from 'react-router-dom';
import i18n from 'i18next';

const LangWrapper = ({ children }) => {
  const { lang } = useParams();
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    const validLanguages = ['en', 'pl', 'de']; // Supported languages

    if (!validLanguages.includes(lang)) {
      // Get the rest of the path after /:lang
      // const restOfPath = location.pathname.substring(`/${lang}`.length);
      // Build the new pathname with the default language
      // const newPathname = `/${i18n.language}${restOfPath}${location.search}`;
      navigate(`/${i18n.language}/not-found`, { replace: true });
    } else if (i18n.language !== lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang, navigate, location.pathname]);

  return children;
};

export default LangWrapper;
