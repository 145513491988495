import React, { useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';


const Regulations = () => {
  const { t, i18n } = useTranslation(); // Access i18n instance
  const lang = i18n.language; // Get the current language
  

  return (
   
      <Container style={{color: "rgb(0, 0, 0)",  paddingTop:"10px" }}>
       <h4>{t("regulations.documents")}</h4>
        <a href='02. Regulamin Organizatora.pdf' rel="nofollow">{t("regulations.regulationsGuide")}</a><br/>
        <a href='05. Formularz reklamacji usługi - www-tourpilot-eu.pdf' rel="nofollow">Formularz reklamacji usługi</a><br/>
        <a href='06. Formularz odstąpienia od umowy świadczenia usług - www-tourpilot-eu.pdf' rel="nofollow">Formularz odstapienia od umowy świadczenia usługi</a><br/>
        <a href='07. Pouczenie o odstąpieniu od umowy świadczenia usług - www-tourpilot-eu.pdf' rel="nofollow">Pouczenie o odstąpieniu od umowy</a><br/>
        <a href='Instrukcja obsługi Organizator.pdf' target="_blank" rel="nofollow">{t("guideAccount.guideInstruction")}</a> <br/>     
      {/* <ol>
        {t("regulations.sections", { returnObjects: true }).map((section, index) => (
          <li key={index}>
            <h4>{section.title}</h4>
            <ul>
              {section.content.map((item, idx) => (
                <li key={idx}>{item}</li>
              ))}
            </ul>
          </li>
        ))}
      </ol> */}
      </Container >
 
  );
};
export default Regulations;
