import React, { useState, useEffect } from 'react';
import GooglePlacesAutocomplete, {
    geocodeByPlaceId,
    getLatLng,
  } from 'react-google-places-autocomplete';

const LocationAutosuggest = ({ onLocationChange ,location}) => {
   const [value, setValue] = useState({}); 
  
   const apiKey = process.env.REACT_APP_GOOGLE_API_KEY;
   useEffect(() => 
   {
    
    if(location)
    { 
      var editValues = {} ;
      editValues.label = location.label;
      editValues.value = {place_id: location.place_id};
      setValue(editValues);
    }
   
  }, [location]);

  const fetchLatLng = async (value) => {
    if (value && value.value && value.value.place_id) {
      try {
        const results = await geocodeByPlaceId(value.value.place_id);
        const latLng = await getLatLng(results[0]);   
        onLocationChange({
          label: value.label,
          location: {
            lat: latLng.lat,
            lng: latLng.lng,
          },
          place_id: value.value.place_id
        });
      } catch (error) {
        console.error('Error fetching geolocation:', error);
      }
    }
    
  };

  const onValueChange = (value) => {
    if (value && value.value && value.value.place_id) {
      setValue(value.value.structured_formatting.main_text);
      // onLocationChange({
      //   label: value.value.structured_formatting.main_text,
      //   location: {
      //     lat: 0,
      //     lng: 0,
      //   },
      //   place_id: value.value.place_id
      // });
      fetchLatLng(value);
    }
};
  return (
    <div>
      <GooglePlacesAutocomplete
      placeholder='Where To ?'
      apiKey={apiKey}
        selectProps={{         
          value,
          onChange: onValueChange,
        }}
        styles={{
            textInput: {
                height: 381,
                color: '#5d5d5d',
                fontSize: 16,
              },
          }}
      />
    </div>
  );
};

export default LocationAutosuggest;