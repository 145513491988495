import React from 'react';
import { Select } from 'antd';


const { Option } = Select;



const LanguageSelector = ({ value = [], onChange }) => {
  const languages = [
    {
      value: 'PL',
      label: 'Polski',
      flag: 'https://flagcdn.com/w320/pl.png',
    },
    {
      value: 'EN',
      label: 'English',
      flag: 'https://flagcdn.com/w320/gb.png',
    },
    {
      value: 'DE',
      label: 'Deutch',
      flag: 'https://flagcdn.com/w320/de.png',
    },
    {
      value: 'ES',
      label: 'Spanish',
      flag: 'https://flagcdn.com/w320/es.png',
    },
    {
      value: 'RU',
      label: 'Russian',
      flag: 'https://flagcdn.com/w320/ru.png',
    },
  ];
  return (
    <Select
      mode="multiple"
      style={{ width: '100%' }}
      placeholder="Select languages"
      value={value}
      onChange={onChange}
      optionLabelProp="label"
    >
      {languages.map(language => (
        <Option key={language.value} value={language.value} label={language.label}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <img
              src={language.flag}
              alt={language.label}
              style={{ width: 20, height: 20, marginRight: 8 }}
            />
            {language.label}
          </div>
        </Option>
      ))}
    </Select>
  );
};

export default LanguageSelector;
