import React, { useState, useEffect } from 'react';

import { Table } from 'antd';
import API_URL from '../config';
import { useTranslation } from 'react-i18next';
import './../toursListMobileComponent.css'; // Importujemy plik CSS dla wersji desktopowej
import DisplayTextComponent from "./DisplayTextComponent.js";
import DisplayTextHelper from './DisplayTextHelper.js';

const ToursListMobileComponent = ({ dataSource, initLoading, loadMore }) => {
    const { t, i18n } = useTranslation(); // Access i18n instance
    const lang = i18n.language; // Get the current language
    const [loading, setLoading] = useState(initLoading);
    useEffect(() => {
        if (dataSource && dataSource.length > 0) {
            setLoading(false); // Wyłączamy ładowanie, kiedy dane są już dostępne
        } else {
            setLoading(true); // Ustawiamy ładowanie na true, jeśli nie ma danych
        }
    }, [dataSource]);
    const columns = [
        {
            title: '',
            dataIndex: 'details',
            key: 'details',
            render: (text, item) => (
                <a href={`/${lang}/tours/${item.id}/${item.region}`} style={{ color: 'inherit', textDecoration: 'none' }} >
                <div className="details-container">
                    <div className="info-container">
                        <div className="tour-title"><a href={`/${lang}/tours/${item.id}/${item.region}`} className="tour-link" rel='dofollow'><DisplayTextComponent data={item.title}/></a></div>
                        <div>{item.region}</div>
                    </div>
                    <div className="image-prices-container">
                        <img
                            width={150}
                            height={150}
                            alt={DisplayTextHelper( {data:item.title} )}
                            src={item.mainImage == null ? '../img/empty.jpg' : `${API_URL}images/tours/${item.id}/thumb/thumb_${item.mainImage}`}
                        />
                        <div className="prices-container">
                            <span dangerouslySetInnerHTML={{ __html: getMobilePrices(item) }} />
                        </div>
                    </div>
                </div>
            </a>
            )
        }
    ];

   

    const getMobilePrices = (item) => {
        if (item.perPerson) {
            try {
                const dataArray = JSON.parse(item.perPersonPriceJson);

                const prices = dataArray.map(item => item.price);
                let min = Math.min(...prices);
                let max = Math.max(...prices);
                if (min !== max) {
                    return min.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + "-" + max.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " " + item.currency;

                } else {
                    return max.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }) + " " + item.currency;

                }

            } catch (error) {
                console.error('Invalid JSON format', error);
            }

        } else {
            return item.price + " " + item.currency;
        }
    }

    const translateType = (value) => {
        if (value === "Adult") {
            return t("priceComponent.adult")
        } else if ("Kid")
            return t("priceComponent.kid")
    };

    const data = dataSource.map((item, index) => ({
        key: item.id,
        ...item
    }));

    return (
        <Table
            columns={columns}
            dataSource={data}
            loading={loading}
            locale={{ emptyText: "" }}
            pagination={false}
            className="tours-list-mobile"
        />
    );
};

export default ToursListMobileComponent;
