import React from 'react';
const getBrowserLanguage = () => {
    const language = navigator.language || navigator.userLanguage;
    return language.split('-')[0]; // zwraca np. "en" z "en-US"
  };
const DisplayTextComponent = ({ data }) => {
    const browserLanguage = getBrowserLanguage();
    let parsedData = [];
    const isJSON = (data) => {
      try {
        parsedData = JSON.parse(data);
        return true;
      } catch (e) {
        return false;
      }
    };
  
    if (!isJSON(data)) {
      return <div>{data}</div>;
    }
    const getContentByLanguage = (language) => {
        if (!language) {
          // Jeśli język nie jest podany, szukaj wartości dla EN
          const contentEN = parsedData.EN;
          if (contentEN !== undefined && contentEN !== '') {
            return contentEN;
          }
      
          // Jeśli EN jest pusty, znajdź pierwszą niepustą wartość
          const entries = Object.entries(parsedData);
          for (let [key, value] of entries) {
            if (value !== undefined && value !== '') {
              return value;
            }
          }
          return null;
        }
      
        // Spróbuj znaleźć wartość dla podanego języka
        const content = parsedData[language.toUpperCase()];
        if (content !== undefined && content !== '') {
          return content;
        } else {
          // Jeśli wartość dla podanego języka jest pusta, spróbuj znaleźć wartość dla EN
          const contentEN = parsedData.EN;
          if (contentEN !== undefined && contentEN !== '') {
            return contentEN;
          }
      
          // Jeśli EN jest pusty, znajdź pierwszą niepustą wartość
          const entries = Object.entries(parsedData);
          for (let [key, value] of entries) {
            if (value !== undefined && value !== '') {
              return value;
            }
          }
          return null;
        }
      };
   
  
    const content = getContentByLanguage(browserLanguage);
  
    return (
      <span>
        {content}</span>
      
    );
  };
export default DisplayTextComponent;
