import React, { useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import '../../blog.css';

const AtrakcjeGdansk = () => {
  const { t, i18n } = useTranslation(); // Access i18n instance
  const lang = i18n.language; // Get the current language


  return (

    <Container style={{ color: "rgb(0, 0, 0)", paddingTop: "10px", fontFamily: "PT Serif" }}>
      <Helmet>
        <html lang={lang} />
        <meta charSet="utf-8" />
        <meta name="description" content={"Gdańsk - " + t("homepage.descriptionBlogtTag")} />
        <meta name="keywords" content={t("homepage.keyWords")} />
        <title>Najlepsze atrakcje turystyczne w Gdańsku</title>
        <link rel="canonical" href={`https://tourpilot.eu/${lang}/blog/gdansk-atrakcje`} />
        <link rel="alternate" href="https://tourpilot.eu/pl/blog/gdansk-atrakcje" hreflang="pl" />
        <link rel="alternate" href="https://tourpilot.eu/en/blog/gdansk-atrakcje" hreflang="en" />
        <link rel="alternate" href="https://tourpilot.eu/pl/blog/gdansk-atrakcje" hreflang="x-default" />
      </Helmet>
      <div className='blog blog-articale-container'>
        <div className='blog-article1'>

          <h1>Atrakcje Turystyczne w Gdańsku - przewodnik</h1>
          <div style={{ width: "100%", height: "300px" }}>
            <img src="img/gdansk.jpg" alt="Przewodnik Gdansk atrakcje" />
          </div>
          <h2>Atrakcje w Gdańsku</h2>
          <p>Gdańsk, perła Trójmiasta, to miasto o niezwykłej historii, bogatej kulturze i niepowtarzalnej atmosferze. Spacerując jego uliczkami, można poczuć ducha minionych epok i odkryć wiele fascynujących miejsc. W tym wpisie przedstawiamy najważniejsze atrakcje turystyczne Gdańska, które koniecznie trzeba odwiedzić.</p>

          <h2>Długi Targ i Fontanna Neptuna</h2>
          <p>Jednym z najbardziej rozpoznawalnych miejsc w Gdańsku jest Długi Targ. To serce miasta, które zachwyca pięknymi kamienicami i bogatą historią. W centrum placu znajduje się Fontanna Neptuna – symbol Gdańska, wokół której toczy się życie turystyczne. Spacerując po Długim Targu, warto zwrócić uwagę na przepiękny Dwór Artusa oraz Ratusz Głównego Miasta, którego wieża oferuje niesamowity widok na panoramę Gdańska.</p>

          <h2>Ulica Mariacka</h2>
          <p>Ulica Mariacka to jedno z najpiękniejszych miejsc w Gdańsku. Znana z malowniczych kamienic, pełnych artystycznych detali i urokliwych przedproży, przyciąga zarówno turystów, jak i mieszkańców. Na jej końcu znajduje się imponująca Bazylika Mariacka, największy ceglany kościół na świecie, którego wnętrze skrywa wiele cennych zabytków. Z wieży Bazyliki rozciąga się zapierający dech w piersiach widok na całe miasto.</p>

          <h2>Europejskie Centrum Solidarności</h2>
          <p>Europejskie Centrum Solidarności to miejsce, które każdy powinien odwiedzić. Muzeum, poświęcone historii ruchu Solidarność i walce o wolność, znajduje się na terenie Stoczni Gdańskiej. Multimedialne wystawy, pełne unikalnych eksponatów, przybliżają zwiedzającym wydarzenia, które miały ogromny wpływ na współczesną Europę. To miejsce nie tylko dla miłośników historii, ale dla wszystkich, którzy chcą lepiej zrozumieć procesy społeczne i polityczne.</p>

          <h2>Muzeum II Wojny Światowej</h2>
          <p>Muzeum II Wojny Światowej w Gdańsku to jedno z najnowocześniejszych muzeów w Europie. Jego misją jest ukazanie tragicznych losów ludzi w czasie wojny oraz edukacja młodszych pokoleń o wydarzeniach tamtych lat. Wystawa główna, rozciągająca się na kilku poziomach, prezentuje wojenne realia w sposób przystępny i poruszający. Interaktywne ekspozycje, filmy i zdjęcia pozwalają na głębokie zrozumienie i refleksję nad historią.</p>

          <h2>Żuraw Gdański</h2>
          <p>Żuraw Gdański, jeden z najbardziej charakterystycznych zabytków miasta, to dawny dźwig portowy z XV wieku. Jest symbolem morskiej potęgi Gdańska i ważnym świadkiem historii handlu. Obecnie Żuraw pełni funkcję oddziału Narodowego Muzeum Morskiego. Wewnątrz można zobaczyć, jak działał mechanizm dźwigu oraz poznać historię gdańskiego portu i jego znaczenie dla rozwoju miasta.</p>

          <h2>Westerplatte</h2>
          <p>Westerplatte to miejsce szczególnie ważne dla Polaków, gdzie 1 września 1939 roku rozpoczęła się II wojna światowa. Warto odwiedzić ten historyczny półwysep, aby oddać hołd obrońcom Westerplatte i poznać historię tego miejsca. Na terenie Westerplatte znajduje się Pomnik Obrońców Wybrzeża, a także pozostałości dawnej strażnicy i koszar.</p>

          <h2>Oliwa i Park Oliwski</h2>
          <p>Gdańsk Oliwa to dzielnica pełna zieleni i spokoju. Katedra Oliwska, słynąca z pięknych organów, to miejsce, gdzie można uczestniczyć w koncertach organowych. Wokół katedry rozciąga się Park Oliwski – idealne miejsce na relaksujący spacer w otoczeniu przyrody. Park, ze swoimi alejkami, stawami i ogrodami, jest oazą spokoju w tętniącym życiem Gdańsku.</p>

          <h2>Zakończenie</h2>
          <p>Gdańsk to miasto, które zachwyca na każdym kroku. Od historycznych zabytków, przez nowoczesne muzea, po malownicze uliczki i parki – każdy znajdzie tu coś dla siebie. Zapraszamy do odkrywania Gdańska z <a href={`https://tourpilot.eu/${lang}`}>tourpilot.eu</a> i korzystania z naszych usług, które sprawią, że każda podróż będzie niezapomnianym przeżyciem.</p>
        </div>
        <div className='blog-articale2'>
        </div>
      </div>
    </Container >

  );
};
export default AtrakcjeGdansk;
