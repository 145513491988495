import React, {useState} from 'react';
import ApiClient from './ApiClient';
import { message,Checkbox ,Modal,Button,Result} from 'antd';
import { useTranslation } from 'react-i18next';

const RegistrationForm = () => {
    const [email, setEmail] = useState('');
    const [firstName, setFirstName] = useState('');
    const [lastName, setLastName] = useState('');
    const [password, setPassword] = useState('');
    const [confirmPassword, setConfirmPassword] = useState('');
    const [isSubmittingRegister, setIsSubmittingRegister] = useState(false); 
    const { t, i18n } = useTranslation(); // Access i18n instance
    const lang = i18n.language; // Get the current language
    const [termsConsent, setTermsConsent] = useState(false);
    const [policyConsent, setPolicyConsent] = useState(false);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const showModal = () => {
        setIsModalOpen(true);
      };
    
      const handleOk = () => {
        setIsModalOpen(false);
        window.location.href = `/${lang}`;
      };  
    const handleSubmit = async (e) => {
        e.preventDefault();

        // Walidacja haseł
        if (password.length < 8 || !/(?=.*\d)(?=.*\W)/.test(password)) {
            message.error(t("login.passworRequirments"));
            return;
        }

        if (password !== confirmPassword) {
            message.error(t("login.passwordDoesntMatch"));
            return;
        }
        if (!termsConsent) {
            message.error(t("login.termsConsentRequired"));
            return;
        }

        if (!policyConsent) {
            message.error(t("login.termsPrivacyRequired"));
            return;
        }
        // Utwórz obiekt z danymi
        const data = {
            firstName,
            lastName,
            email,
            password,
            termsconsent: termsConsent, 
            marketingconsent: false 
        };

        try {
            setIsSubmittingRegister(true);
            // Wyślij dane do API
            const response = await ApiClient('POST', 'api/auth/register',data);

            if (response.ok) {
                
                message.success(t("login.emailConfirmationCheck"));
                showModal(true);
            }else if (response.status === 409) {
                   
                message.info(t("login.emailTaken"));
                }
            else {
                // Obsłuż błąd rejestracji
                message.error(t("message.error"));
            }
        } catch (error) {
            message.error(error);
        }finally {
            // Enable the button after the form submission is complete
            setIsSubmittingRegister(false);
        }
    };
    const handleCheckboxPolicy = (e) => {
        setPolicyConsent(e.target.checked);
    }
    const handleCheckboxTerms = (e) => {
        setTermsConsent(e.target.checked);
    }
    return (
        <form className="form-container" onSubmit={handleSubmit}>
                
            <div>
                <label>{t("login.firstName")}:*</label>
                <input
                    type="text"
                    value={firstName}
                    onChange={(e) => setFirstName(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>{t("login.lastName")}:*</label>
                <input
                    type="text"
                    value={lastName}
                    onChange={(e) => setLastName(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>{t("login.email")}:</label>
                <input
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>{t("login.password")}:</label>
                <input
                    type="password"
                    value={password}
                    onChange={(e) => setPassword(e.target.value)}
                    required
                />
            </div>
            <div>
                <label>{t("login.confrimPassword")}</label>
                <input
                    type="password"
                    value={confirmPassword}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    required
                />
            </div>
            <div>           
                <Checkbox                    
                    onChange={handleCheckboxTerms}                   
                    required
                >{t("login.termsConsent")} <a href='01. Regulamin Seriwsu internetowego.pdf' target="_blank">{t("regulations.regulations")}</a>*</Checkbox>
                <Checkbox                    
                    onChange={handleCheckboxPolicy}                   
                    required
                >{t("login.policyConsent")} <a href='privacy policy.pdf' target="_blank">{t("login.policy")}</a>*</Checkbox>

            </div>
            <button type="submit" disabled={isSubmittingRegister}>{isSubmittingRegister ? t("login.inProgres")  : t("login.register")} </button>
            <Modal open={isModalOpen}  footer={[
          <Button key="ok" onClick={handleOk}>
            OK
          </Button>          
        ]}>
            <Result
    status="success"
    title={t("login.emailConfirmationCheck") + " "  + t("login.emailConfirmationCheck1")} 

   
  />
            </Modal>
        </form>
    );
};


export default RegistrationForm;
