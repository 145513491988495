import React, { useContext, useEffect, useRef, useState } from 'react';
import { Button, Form, Input, Popconfirm, Table, Select,InputNumber } from 'antd';
import { useTranslation } from 'react-i18next';
const EditableContext = React.createContext(null);


const EditableRow = ({ index, ...props }) => {
  const [form] = Form.useForm();
  return (
    <Form form={form} component={false}>
      <EditableContext.Provider value={form}>
        <tr {...props} />
      </EditableContext.Provider>
    </Form>
  );
};
const EditableCell = ({
  title,
  editable,
  children,
  dataIndex,
  record,
  handleSave,
  ...restProps
}) => {
  const [editing, setEditing] = useState(false);
  const inputRef = useRef(null);

  const form = useContext(EditableContext);
  useEffect(() => {
    if (editing) {
      inputRef.current?.focus();
    }
  }, [editing]);


  const toggleEdit = () => {
    setEditing(!editing);
    form.setFieldsValue({
      [dataIndex]: record[dataIndex],
    });
  };
  const save = async () => {
    try {
      const values = await form.validateFields();
      toggleEdit();
      handleSave({
        ...record,
        ...values,
      });
    } catch (errInfo) {
      console.log('Save failed:', errInfo);
    }
  };
  let childNode = children;
  if (editable) {
    childNode = editing ? (
      <Form.Item
        style={{
          margin: 0,
        }}
        name={dataIndex}
        rules={[
          {
            required: true,
            message: `${title} is required.`,
          },
        ]}
      >
        <Input ref={inputRef} onPressEnter={save} onBlur={save} />
      </Form.Item>
    ) : (
      <div
        className="editable-cell-value-wrap"
        style={{
          paddingRight: 24,
        }}
        onClick={toggleEdit}
      >
        {children}
      </div>
    );
  }
  return <td {...restProps}>{childNode}</td>;
};
const PriceComponent = ({ onPriceChange,dataSourceToSet }) =>  {
  const [personType, setPersonType] = useState();
  const [personTypeDataSource, setPersonTypeDataSource] = useState([]);
  const [price, setPrice] = useState(null);
  const [ageFrom, setAgeFrom] = useState(null);
  const [ageTo, setAgeTo] = useState(null);
  const { t, i18n } = useTranslation(); // Access i18n instance
  const lang = i18n.language; // Get the current language
  const [dataSource, setDataSource] = useState([ 
  ]);
  const [count, setCount] = useState(2);
  useEffect(() => {
    onPriceChange(dataSource);
            
  }, [dataSource]);
  useEffect(() => {
    if(dataSourceToSet.length > 0)
      {
        setDataSource(JSON.parse(dataSourceToSet));

      }
            
  },[dataSourceToSet] );
  
  const handleDelete = (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
  };
  const defaultColumns = [    
    {
      title: t('priceComponent.type'),
      dataIndex: 'name',
      width: '30%',
     
    },
    {
      title: t('priceComponent.ageFrom'),
      dataIndex: 'ageFrom',      
    },
    {
      title: t('priceComponent.ageTo'),
      dataIndex: 'ageTo',     
    },
    {
      title: t('priceComponent.price'),
      dataIndex: 'price',      
    },
    {
      title: t('priceComponent.operation'),
      dataIndex: 'operation',
      render: (_, record) =>
        dataSource.length >= 1 ? (
          <Popconfirm title={t('priceComponent.deleteYesNo')}  onConfirm={() => handleDelete(record.key)}>
            <a>{t('priceComponent.delete')}</a>
          </Popconfirm>
        ) : null,
    },
  ];
  const handleAdd = () => {
    const maxKey = Math.max(...dataSource.map(item => item.key), 0);
    const newData = {
      key: maxKey + 1,
      name: personType,
      ageFrom: ageFrom,
      ageTo: ageTo,
      price: price,
    };
    setDataSource([...dataSource, newData]);
    setCount(count + 1);
  };
  const handleSave = (row) => {
    const newData = [...dataSource];
    const index = newData.findIndex((item) => row.key === item.key);
    const item = newData[index];
    newData.splice(index, 1, {
      ...item,
      ...row,
    });
    setDataSource(newData);
  };
  const handlePersonTypeChange = (value) => {
    setPersonType(value);
    if(value === "Kid")
      {
        setAgeFrom(0);
        setAgeTo(17);
      }else{
        setAgeFrom(0);
        setAgeTo(99);
      }
  }
  const handleAgeFromChange = (value) => {
    setAgeFrom(value);
  }
  const handleAgeToChange = (value) => {
    setAgeTo(value);
  }
  const handlePriceChange = (value) => {
    setPrice(value);
  }
  const components = {
    body: {
      row: EditableRow,
      cell: EditableCell,
    },
  };
  const columns = defaultColumns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => ({
        record,
        editable: col.editable,
        dataIndex: col.dataIndex,
        title: col.title,
        handleSave,
      }),
    };
  });
  return (
    <div>
      <Select
        style={{ width: 120 }}
        onChange={handlePersonTypeChange}
        value={personType}
        options={[
          { value: 'Adult', label: 'Adult' },
          { value: 'Kid', label: 'Kid' },         
         
        ]}
      />
      <InputNumber onChange={handleAgeFromChange} value={ageFrom} placeholder={t('priceComponent.ageFrom')} min={0} max={99}/>
      <InputNumber onChange={handleAgeToChange} value={ageTo}  placeholder={t('priceComponent.ageTo')} min={0} max={99}/>
      <InputNumber onChange={handlePriceChange} value={price}  placeholder={t('priceComponent.price')} min={0} />
      <Button
        onClick={handleAdd}
        type="primary"
        style={{
          marginBottom: 16,
        }}
      >
        {t('priceComponent.add')}
      </Button>
      <Table
        components={components}
        rowClassName={() => 'editable-row'}
        bordered
        dataSource={dataSource}
        columns={columns}
        pagination={false}
      />
    </div>
  );
};
export default PriceComponent;