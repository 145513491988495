import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import LoginUpperSection from './LoginUpperSection';
import LoginLowerSection from './LoginLowerSection';
import { ResetPasswordFrom } from './ResetPasswordForm';
import { useParams } from 'react-router-dom';

const ResetPasswordPage = () => {
    
    const { token } = useParams();

    return (
        <Container>
            <LoginUpperSection />
            <Row style={{ backgroundColor: 'rgb(51, 51, 51)' }}>
                <Col md={3} className="mx-auto">              
                    <ResetPasswordFrom resettoken={token} />
                </Col>
            </Row>
            <LoginLowerSection />
        </Container>
    );
};
export default ResetPasswordPage;
