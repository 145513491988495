
import API_URL from '../config';

const ApiClient = async (method, endpoint, data = null, headers = {},fileList = []) => {
    try {
      // Ustawienia nagłówków żądania
      const defaultHeaders = {
        'Content-Type': 'application/json',
        // Jeśli istnieje token, dodaj go do nagłówków
        ...(localStorage.getItem('accessToken') && {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }),
      };
  
      const mergedHeaders = { ...defaultHeaders, ...headers };
  
      // Utwórz obiekt opcji dla funkcji fetch
      const requestOptions = {
        method: method,
        headers: mergedHeaders,
        // Dodaj ciało żądania, jeśli istnieje
        body: data ? JSON.stringify(data) : undefined,
      };
  
      // Składamy pełny URL
      const apiUrl = `${API_URL}${endpoint}`;
  
      // Wywołaj funkcję fetch
      const response = await fetch(apiUrl, requestOptions);
      return response;
      // Sprawdź, czy odpowiedź jest udana (status 200-299)
      
    } catch (error) {
      console.error('Error:', error);
      throw error;
    }
  };

  export default ApiClient;