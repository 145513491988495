import React, { useState, useEffect } from 'react';
import { Container, Row } from 'react-bootstrap';
import { useTranslation } from 'react-i18next';
import { Helmet } from "react-helmet";
import '../../blog.css';
const AtrakcjePoznan = () => {
  const { t, i18n } = useTranslation(); // Access i18n instance
  const lang = i18n.language; // Get the current language


  return (

    <Container style={{ color: "rgb(0, 0, 0)", paddingTop: "10px", fontFamily: "PT Serif" }}>
      <Helmet>
        <html lang={lang} />
        <meta charSet="utf-8" />
        <meta name="description" content={"Poznań - " + t("homepage.descriptionBlogtTag")} />
        <meta name="keywords" content={t("homepage.keyWords")} />
        <title>Najlepsze atrakcje turystyczne na Mazurach</title>
        <link rel="canonical" href={`https://tourpilot.eu/${lang}/blog/poznan-atrakcje`} />
        <link rel="alternate" href="https://tourpilot.eu/pl/blog/poznan-atrakcje" hreflang="pl" />
        <link rel="alternate" href="https://tourpilot.eu/en/blog/poznan-atrakcje" hreflang="en" />
        <link rel="alternate" href="https://tourpilot.eu/pl/blog/poznan-atrakcje" hreflang="x-default" />
      </Helmet>
      <div className='blog blog-articale-container'>
        <div className='blog-article1'>

          <h1>Atrakcje Turystyczne po Poznaniu - przewodnik</h1>
          <div style={{ width: "100%", height: "300px" }}>
            <img src="img/poznan.jpg" alt="Przewodnik Poznan atrakcje" />
          </div>

          <p>Poznań – miasto z bogatą historią, dynamicznie rozwijającą się sceną kulturalną i wieloma atrakcjami turystycznymi, które przyciągają zarówno turystów, jak i mieszkańców Polski. Oto kilka miejsc, które warto odwiedzić, będąc w Poznaniu.</p>

          <h2>1. Stary Rynek i Ratusz</h2>
          <p>Stary Rynek to serce Poznania, jedno z najpiękniejszych rynków w Polsce, otoczone kolorowymi kamienicami i historycznymi budowlami. Na jego środku znajduje się renesansowy ratusz, który jest jednym z symboli miasta. Każdego dnia o godzinie 12:00 na wieży ratuszowej pojawiają się słynne poznańskie koziołki, które trykają się rogami – to tradycja, którą warto zobaczyć!</p>

          <h2>2. Zamek Cesarski</h2>
          <p>Zamek Cesarski w Poznaniu to jeden z najmłodszych zamków w Europie, zbudowany na początku XX wieku dla cesarza Wilhelma II. Dziś jest to centrum kulturalne z licznymi wystawami, koncertami i teatrami. Otoczenie zamku to również urokliwy park, który stanowi idealne miejsce na spacer.</p>

          <h2>3. Katedra Poznańska i Ostrów Tumski</h2>
          <p>Ostrów Tumski, wyspa nad Wartą, to miejsce, gdzie rozpoczęła się historia państwa polskiego. Znajduje się tam Bazylika Archikatedralna Świętych Apostołów Piotra i Pawła, czyli katedra poznańska, gdzie prawdopodobnie pochowano pierwszych władców Polski, w tym Mieszka I. To miejsce pełne historycznego znaczenia, którego nie można pominąć.</p>

          <h2>4. Jezioro Maltańskie</h2>
          <p>Dla osób preferujących aktywny wypoczynek idealnym miejscem będzie Jezioro Maltańskie. To sztuczne jezioro w centrum Poznania otoczone jest trasami spacerowymi, rowerowymi oraz licznymi atrakcjami, takimi jak Malta Ski – stok narciarski, tor saneczkowy oraz Termy Maltańskie – jeden z największych kompleksów basenów w Polsce.</p>

          <h2>5. Palmiarnia Poznańska</h2>
          <p>W Parku Wilsona znajduje się Palmiarnia Poznańska – największa w Polsce i jedna z największych w Europie palmiarnia. Znajdziemy tam ponad tysiąc gatunków roślin z różnych stref klimatycznych oraz egzotyczne zwierzęta. To wyjątkowe miejsce dla miłośników przyrody i tropikalnej flory.</p>

          <h2>6. Muzeum Narodowe w Poznaniu</h2>
          <p>Dla miłośników sztuki obowiązkowym punktem jest Muzeum Narodowe w Poznaniu, które posiada jedną z najbogatszych kolekcji malarstwa w Polsce. W zbiorach można podziwiać dzieła sztuki europejskiej, polskiej oraz wybitne prace z okresu modernizmu.</p>

          <h2>7. Rogalowe Muzeum Poznania</h2>
          <p>Poznań słynie również z rogali świętomarcińskich – tradycyjnego wypieku z białym makiem. W Rogalowym Muzeum Poznania można poznać historię i tajniki przygotowywania tych słodkich przysmaków, a także wziąć udział w interaktywnym pokazie ich tworzenia.</p>

          <h2>8. Cytadela – Park Cytadela</h2>
          <p>Cytadela to największy park w Poznaniu, położony na terenie dawnej twierdzy. To idealne miejsce na relaks, spacery czy piknik. Znajduje się tam także Muzeum Uzbrojenia oraz cmentarze wojskowe, co czyni Cytadelę miejscem o dużym znaczeniu historycznym.</p>

          <h2>9. Brama Poznania ICHOT</h2>
          <p>Brama Poznania to interaktywne centrum interpretacji historii Ostrowa Tumskiego. Nowoczesna ekspozycja multimedialna prowadzi zwiedzających przez ponad tysiącletnią historię miasta i regionu, ukazując najważniejsze wydarzenia i postacie związane z Poznaniem.</p>

          <h2>10. Stary Browar</h2>
          <p>Stary Browar to unikalne połączenie centrum handlowego z galerią sztuki współczesnej. Mieści się w zabytkowym budynku dawnego browaru Huggerów i stanowi ważny punkt na mapie kulturalnej miasta. Można tam nie tylko zrobić zakupy, ale też uczestniczyć w wydarzeniach artystycznych i kulturalnych.</p>

          <p>Poznań to miasto, które oferuje wiele atrakcji zarówno dla miłośników historii, sztuki, jak i aktywnego wypoczynku. Bez względu na to, czy preferujesz zwiedzanie zabytków, spacery po parkach, czy odkrywanie lokalnych smaków, każdy znajdzie tu coś dla siebie.</p>
        </div>
      </div>
    </Container >

  );
};
export default AtrakcjePoznan;
