import React, { useState, useEffect } from 'react';
import { Input, Button, Form } from 'antd';
import { useTranslation } from 'react-i18next';

const PerPersonComponent = ({ onPriceChange, onPersonsChange, currency, priceForAll = 0, data = "[]", perPerson = false ,maxCapacity=0}) => {
  const { t, i18n } = useTranslation(); // Access i18n instance
  const lang = i18n.language; // Get the current language

  // Parsowanie danych
  let parsedData;
  if (typeof data === 'string') {
    if (data.trim() !== '') {
      try {
        parsedData = JSON.parse(data);
      } catch (error) {
        console.error('Invalid JSON format', error);
        parsedData = [];
      }
    }
  } else if (Array.isArray(data)) {
    parsedData = data;
  } else if (typeof data === 'object' && data !== null) {
    parsedData = [data];
  } else {
    parsedData = [];
  }

  const [counts, setCounts] = useState(() => parsedData.map(() => 0));
  const [sumPrice, setSumPrice] = useState(0);
  const [countsPriceAll, setCountsPriceAll] = useState(0);

  useEffect(() => {
    setCounts(parsedData.map(() => 0));
    setSumPrice(0);
    setCountsPriceAll(0);

    onPriceChange(sumPrice);
    updatePersons();
  }, [data]);
  useEffect(() => {
    updatePersons();
  }, [counts]);
  const updatePersons = () => {

    const selectedPersons = counts.reduce((acc, count, idx) => {
      if (count > 0) {
        acc.push({ key: parsedData[idx].key, name: parsedData[idx].name, count });
      }
      return acc;
    }, []);
    onPersonsChange(selectedPersons);
  };

  const increment = (index, price) => {
    const newCounts = [...counts];
    if(maxCapacity > newCounts[index])
      {
        newCounts[index] += 1;
        setCounts(newCounts);
        setSumPrice(sumPrice + price);
        onPriceChange(sumPrice + price);
      }
   
    // updatePersons();
  };

  const decrement = (index, price) => {
    const newCounts = [...counts];
    if (newCounts[index] > 0) {
      newCounts[index] -= 1;
      setCounts(newCounts);
      setSumPrice(sumPrice - price);
      onPriceChange(sumPrice - price);
      // updatePersons();
    }
  };

  const incrementPriceAll = () => {
    if(maxCapacity > countsPriceAll )
      {
        setCountsPriceAll(countsPriceAll + 1);
        setSumPrice(priceForAll);
        onPriceChange(priceForAll);
        onPersonsChange([{ key: '0', name: 'All', count: countsPriceAll + 1 }]);
      }
   
  };

  const decrementPriceAll = () => {
    if (countsPriceAll === 0)
      return;
    setCountsPriceAll(countsPriceAll - 1);
    setSumPrice(priceForAll);
    onPriceChange(priceForAll);
    onPersonsChange([{ key: '0', name: 'All', count: countsPriceAll - 1 }]);
  };

  if (!Array.isArray(parsedData)) {
    return null;
  }
  const translateType = (value) => {
    if (value === "Adult") {
      return t("priceComponent.adult")
    } else if ("Kid")
      return t("priceComponent.kid")

  };
  return (
    <div>
    {perPerson && (
      <Form layout="vertical">
        {parsedData.map((item, index) => (
          <Form.Item
            key={`${item.key}-${item.name}`}
            label={`${translateType(item.name)} (${t('priceComponent.age')}: ${item.ageFrom}-${item.ageTo}) `}>
            <div style={{ margin: '0 0 10px' }}>
              {t('common.price')} :{' '}
              {item.price.toLocaleString('de-DE', {
                minimumFractionDigits: 2,
                maximumFractionDigits: 2,
              })}{' '}
              {currency}
            </div>
            <Button onClick={() => decrement(index, item.price)}>-</Button>
            <span style={{ margin: '0 10px' }}>{counts[index]}</span>
            <Button onClick={() => increment(index, item.price)}>+</Button>
          </Form.Item>
        ))}
      </Form>
    )}

    {!perPerson && (
      <Form layout="vertical">
        <Form.Item>
          <Button onClick={() => decrementPriceAll()}>-</Button>
          <span style={{ margin: '0 10px' }}>
            {t('reservations.persons')} : {countsPriceAll}
          </span>
          <Button onClick={() => incrementPriceAll()}>+</Button>
        </Form.Item>
      </Form>
    )}
  </div>
);

//       <div hidden={!perPerson}>
//         <Form layout="vertical">
//           {parsedData.map((item, index) => (
//             <Form.Item
//               key={`${item.key}-${item.name}`}
//               label={`${translateType(item.name)} (${t("priceComponent.age")}: ${item.ageFrom}-${item.ageTo}) `}>
//               <div style={{ margin: '0 0 10px' }}>{t('common.price')} : {item.price.toLocaleString('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 })} {currency}</div>
//               <Button onClick={() => decrement(index, item.price)}>-</Button>
//               <span style={{ margin: '0 10px' }}>{counts[index]}</span>
//               <Button onClick={() => increment(index, item.price)}>+</Button>
//             </Form.Item>
//           ))}
//         </Form>
//       </div>
//       <div hidden={perPerson}>
//         <Form layout="vertical">
//           <Form.Item>
//             <Button onClick={() => decrementPriceAll()}>-</Button>
//             <span style={{ margin: '0 10px' }}>{t('reservations.persons')} : {countsPriceAll}</span>
//             <Button onClick={() => incrementPriceAll()}>+</Button>
//           </Form.Item>
//         </Form>
//       </div>
//     </div>
//   );
};

export default PerPersonComponent;
