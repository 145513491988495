import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import backend from "i18next-http-backend";
import translationEn from "../src/lang/translation.en.json";
import translationPl from "../src/lang/translation.pl.json";

const options = {
  order: ['navigator'],
  lookupQuerystring: 'lang'
}

const languages = ["pl", "en"]; // Języki obsługiwane

const resources = {
  en: {
    translation: translationEn,
  },
  pl: {
    translation: translationPl,
  },
};

i18n
  .use(initReactI18next) // Passes i18n down to react-i18next
  .use(LanguageDetector) // Detects the user's language
  .use(backend) // Backend plugin for loading translations
  .init({
    resources, // Użyjemy zasobów zdefiniowanych powyżej
    fallbackLng: 'pl', // Domyślnie używany język, jeśli nie zostanie wykryty inny
    debug: false, // Można ustawić na true, aby zobaczyć więcej logów podczas debugowania
    detection: options, // Ustawienia detekcji języka
    interpolation: {
      escapeValue: false, // React już domyślnie escapuje wartości
    },
    supportedLngs: languages, // Lista obsługiwanych języków
  });

export default i18n;
